import { apiSlice } from '../../app/api/apiSlice';

export const commissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommissions: builder.query({
      query: () => ({
        url: '/commissions',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({ type: 'Commission', id })),
        { type: 'Commission', id: 'LIST' },
      ],
    }),
    getCommission: builder.query({
      query: (id) => `/commission/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [{ type: 'Commission', id: arg }],
    }),
    createCommission: builder.mutation({
      query: (body) => ({
        url: '/commission',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Commission', id: 'LIST' }],
    }),
    updateCommission: builder.mutation({
      query: ({ commissionId, ...payload }) => ({
        url: `/commission/${commissionId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Commission', id: arg.id },
      ],
    }),
    deleteCommission: builder.mutation({
      query: (id) => ({
        url: `/commission/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Commission', id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetCommissionsQuery,
  useGetCommissionQuery,
  useCreateCommissionMutation,
  useUpdateCommissionMutation,
  useDeleteCommissionMutation,
} = commissionApiSlice;
