import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// components
import Sidebar from '../../Sidebar/Sidebar.js';
import NavbarUser from 'components/Navbars/NavbarUser.js';
import HeaderStatCards from '../../Headers/Admin/HeaderStatCards.js';
import FooterAdmin from '../../Footers/Admin/FooterAdmin.js';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import aspirator from '../../../menu/aspirator';
import { useHistory, useLocation } from 'react-router-dom';
import admin from '../../../menu/admin';
import dewan from '../../../menu/dewan';
import operator from '../../../menu/operator';
import brandImg from 'assets/img/brand/tim_60x61.png';

export default function Admin1({ sidebar, navbar, header, footer, children }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const history = useHistory();
  const { auth } = useSelector((state) => state);
  if (!auth.token) {
    history.push('/login');
    return null;
  }
  const decoded = jwtDecode(auth?.token);
  const tokenRole = decoded?.sub?.role;
  const brand = {
    text: process.env.REACT_APP_NAME,
    image: brandImg,
    link: {
      href: '/',
    },
  };

  let menus = [];
  if (tokenRole === 'aspirator') {
    menus = aspirator;
  } else if (tokenRole === 'admin') {
    menus = admin;
  } else if (tokenRole === 'dewan') {
    menus = dewan;
  } else if (tokenRole === 'operator') {
    menus = operator;
  } else {
    menus = [
      {
        divider: true,
      },
      {
        title: 'Section 1',
      },
      {
        icon: 'fas fa-tv',
        text: 'Dashboard',
        active: true,
        link: {
          href: '/dashboard',
        },
      },
    ];
  }

  return (
    <>
      <Sidebar brand={brand} activeColor="lightBlue" items={menus} />
      <div className="relative md:ml-64 bg-blueGray-200">
        <NavbarUser {...navbar} />
        <HeaderStatCards {...header} />
        <div className="px-4 md:px-10 mx-auto w-full -mt-24">
          {children || (
            <div className="relative w-full h-600-px bg-white text-center flex flex-col items-center justify-center content-center border border-solid border-blueGray-200 rounded p-40">
              <h1 className="text-6xl font-normal leading-normal mt-0 mb-2">
                Here will be your content, instead of this box!
              </h1>
              <p>
                You can add one of the width utility classes to make it a little
                smaller.
              </p>
            </div>
          )}
          <FooterAdmin {...footer} />
        </div>
      </div>
    </>
  );
}

Admin1.defaultProps = {
  sidebar: {},
  navbar: {},
  header: {},
  footer: {},
};

Admin1.propTypes = {
  // props to pass to the Sidebar component
  sidebar: PropTypes.object,
  // props to pass to the NavbarSearchUser component
  navbar: PropTypes.object,
  // props to pass to the HeaderStatCards component
  header: PropTypes.object,
  // props to pass to the FooterAdmin component
  footer: PropTypes.object,
};
