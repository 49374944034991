import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// sections
// components
import NavbarLinks from 'components/Navbars/NavbarLinks.js';
import HeaderImageTitleCenter from 'components/Headers/Presentation/HeaderImageTitleCenter.js';
import FooterLarge from 'components/Footers/Landing/FooterLarge.js';
// texts for sections as props
import footerlarge from '_texts/Landing/footerlarge.js';
import { useSelector } from 'react-redux';

import LandingBg from '../../assets/img/landing_bg.jpg';

export default function Landing1() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { auth } = useSelector((state) => state);
  let rightLinks = [];
  if (auth.isAuthenticated) {
    rightLinks = [
      {
        href: '/dashboard',
        children: 'Masuk',
      },
    ];
  } else {
    rightLinks = [
      {
        href: '/login',
        children: 'Masuk',
      },
      {
        children: 'atau',
      },
      {
        href: '/register',
        children: 'Daftar',
      },
    ];
  }
  const navbarProps = {
    color: 'transparent',
    type: 'absolute',
    logoText: 'e-Asmara',
    logoLink: { to: '/' },
    socials: [
      {
        icon: 'facebook',
        text: 'Share on Facebook',
        link: 'https://www.facebook.com/sekretariatdprdpemalang/',
      },
      {
        icon: 'twitter',
        text: 'Tweet on Twitter',
        link: 'https://twitter.com/DPRD_Pemalang',
      },
    ],
    rightLinks,
  };
  return (
    <>
      <NavbarLinks {...navbarProps} />
      <main>
        <HeaderImageTitleCenter
          bgImage={LandingBg}
          title={process.env.REACT_APP_NAME}
          description={process.env.REACT_APP_DESCRIPTION}
        />
      </main>
      <div className="-mt-20">
        <FooterLarge {...footerlarge} />
      </div>
    </>
  );
}
