import { apiSlice } from '../../app/api/apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all users with pagination, sorting and filtering
    getUsers: builder.query({
      query: (params) => {
        const { page } = params;
        const query = new URLSearchParams({
          page: page.toString(),
        });
        return `/users?${query.toString()}`;
      },
      transformResponse: (responseData) => {
        return responseData.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({ type: 'User', id })),
        { type: 'User', id: 'LIST' },
      ],
    }),

    // Get user by id
    getUserById: builder.query({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'User', id: arg }],
    }),

    // Add new user
    addNewUser: builder.mutation({
      query: (newUser) => ({
        url: '/user',
        method: 'POST',
        body: { ...newUser },
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),

    // Update user
    updateUser: builder.mutation({
      query: ({ userId, ...updateUser }) => ({
        url: `/user/${userId}`,
        method: 'PUT',
        body: updateUser,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
    }),

    // Get user profile
    getUserProfile: builder.query({
      query: () => '/user/profile',
      transformResponse: (responseData) => {
        return responseData.data;
      },
      providesTags: (result, error, arg) => [{ type: 'User', id: 'PROFILE' }],
    }),

    // Update user profile
    updateUserProfile: builder.mutation({
      query: (updateUserProfile) => ({
        url: '/user/profile',
        method: 'PUT',
        body: updateUserProfile,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: 'PROFILE' },
      ],
    }),

    // Change user password
    changeUserPassword: builder.mutation({
      query: (changeUserPassword) => ({
        url: '/user/change-password',
        method: 'PATCH',
        body: changeUserPassword,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: 'PROFILE' },
      ],
    }),

    // Request password reset
    requestPasswordReset: builder.mutation({
      query: (requestPasswordReset) => ({
        url: `/user/request-reset-password/${requestPasswordReset}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: 'PROFILE' },
      ],
    }),

    // Banned user
    bannedUser: builder.mutation({
      query: (bannedUser) => ({
        url: `/users/${bannedUser}/banned`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
    }),

    // Unbanned user
    unbannedUser: builder.mutation({
      query: (unbannedUser) => ({
        url: `/users/${unbannedUser}/unbanned`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
    }),

    // statistics
    statistics: builder.query({
      query: () => '/users/statistic',
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useChangeUserPasswordMutation,
  useRequestPasswordResetMutation,
  useBannedUserMutation,
  useUnbannedUserMutation,
  useStatisticsQuery,
} = userApiSlice;
