import { apiSlice } from '../../app/api/apiSlice';

export const aspirationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get Aspirations with pagination
    getAspirations: builder.query({
      query: (page) => ({
        url: `/aspirations?page=${page}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      // providesTags if result is not empty
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Aspiration',
                id,
              })),
              { type: 'Aspiration', id: 'LIST' },
            ]
          : [],
    }),
    getAspiration: builder.query({
      query: (id) => `/aspiration/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Aspiration',
          id: arg,
        },
      ],
    }),
    getMyAspirations: builder.query({
      query: () => ({
        url: '/my-aspirations',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Aspiration',
                id,
              })),
              { type: 'Aspiration', id: 'LIST' },
            ]
          : [],
    }),
    getMyAspiration: builder.query({
      query: (id) => `/my-aspiration/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Aspiration',
          id: arg,
        },
      ],
    }),
    createMyAspiration: builder.mutation({
      query: (body) => ({
        url: '/my-aspiration',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Aspiration', id: 'LIST' }],
    }),
    createAspirationProposal: builder.mutation({
      query: ({ proposalId, ...payload }) => ({
        url: `/aspiration/proposal/${proposalId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Aspiration', id: 'LIST' }],
    }),
    updateMyAspiration: builder.mutation({
      query: ({ aspirationId, ...payload }) => ({
        url: `/my-aspiration/${aspirationId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Aspiration', id: arg.id },
      ],
    }),
    deleteMyAspiration: builder.mutation({
      query: (id) => ({
        url: `/my-aspiration/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Aspiration', id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetAspirationsQuery,
  useGetAspirationQuery,
  useGetMyAspirationsQuery,
  useGetMyAspirationQuery,
  useCreateMyAspirationMutation,
  useCreateAspirationProposalMutation,
  useUpdateMyAspirationMutation,
  useDeleteMyAspirationMutation,
} = aspirationApiSlice;
