import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import Admin1 from '../../components/Layouts/Admin/Admin1';
import { useEffect, useState } from 'react';
import {
  useGetConstituencyQuery,
  useUpdateConstituencyMutation,
} from './constituencyApiSlice';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function ConstituencyEdit() {
  const history = useHistory();
  const constituencyId = useParams();
  const [name, setName] = useState('');

  const {
    data: constituency,
    isLoading: isLoadingConstituency,
    isSuccess: isSuccessConstituency,
  } = useGetConstituencyQuery(constituencyId.constituencyId);

  useEffect(() => {
    if (isSuccessConstituency) {
      setName(constituency.name);
    }
  }, [constituency, isSuccessConstituency]);

  const [
    updateConstituency,
    { isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateConstituencyMutation();

  useEffect(() => {
    if (isSuccessUpdate) {
      toast.success('Succesfully updated constituency', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/constituency');
    } else if (isErrorUpdate) {
      toast.error(`Error updating constituency ${errorUpdate?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccessUpdate, isErrorUpdate, errorUpdate]);

  const onSaveClicked = async () => {
    try {
      await updateConstituency({
        id: constituencyId.constituencyId,
        name,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">Ubah Dapil</h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    nama
                  </label>
                  <Input
                    type="text"
                    placeholder="E.g. Pemalang 1"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
