import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const fractionSlice = createSlice({
  name: 'fraction',
  initialState,
  reducers: {
    getFraction: (state, action) => {
      return action.payload;
    },
    setFractions: (state, action) => {
      state.push(action.payload);
    },
    removeFraction: (state, action) => {
      return state.filter((fraction) => fraction.id !== action.payload);
    },
  },
});

export default fractionSlice.reducer;
export const {
  getFraction,
  setFractions,
  removeFraction,
} = fractionSlice.actions;
