import navbarlinks from '../../_texts/auth/navbarlinks';
import footerauth from '../../_texts/auth/footers/footerauth';
import Auth1 from '../../components/Layouts/Auth/Auth1';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useEffect, useState } from 'react';
import { useForgotPasswordMutation } from './authApiSlice';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [
    forgotPassword,
    { isLoading, isSuccess, isError, error },
  ] = useForgotPasswordMutation();
  const onClickReset = async (e) => {
    try {
      await forgotPassword({ email }).unwrap();
      setEmail('');
    } catch (err) {
      Sentry.captureException('error ', err);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(`Password reset link sent to ${email}`, {
        position: 'top-center',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (isError) {
      toast.error(error?.data?.data, {
        position: 'top-center',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [isSuccess, isError, error]);

  return (
    <Auth1
      image={require('assets/img/illustrations/reset.svg').default}
      navbar={navbarlinks}
      footer={footerauth}
    >
      <div className="container mx-auto px-4">
        <div className="justify-center flex flex-wrap -mx-4">
          <div className="px-4 relative w-full lg:w-5/12 w-full md:w-8/12">
            <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
              <div className="pb-6 rounded-t-lg overflow-hidden">
                <img
                  src={require('assets/img/illustrations/linth3.svg').default}
                  alt="..."
                  className="w-full rounded-t-lg"
                  style={{ marginTop: '-46%' }}
                />
              </div>

              <div className="lg:p-10 px-4 py-4 flex-auto rounded-b-lg">
                <h4 className="text-2xl font-bold text-center">
                  Reset Password
                </h4>
                <div className="text-center text-blueGray-500 mb-5 mt-1">
                  <p>Masukkan alamat email anda</p>
                </div>
                <Input
                  leftIcon="fas fa-envelope"
                  type="email"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className="text-center mt-6">
                  <Button
                    color="dark"
                    children="Reset"
                    onClick={onClickReset}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auth1>
  );
}
