import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import Admin1 from '../../components/Layouts/Admin/Admin1';
import { useCreateCommissionMutation } from './commissionApiSlice';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function CommissionCreate() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const history = useHistory();

  const [
    createCommission,
    { isSuccess: isCreateSuccess, isError: isCreateError, error: errorCreate },
  ] = useCreateCommissionMutation();

  const onSaveCommission = async () => {
    try {
      await createCommission({ name, description });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isCreateError) {
      toast.error(`Error creating commission ${errorCreate?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isCreateSuccess) {
      toast.success('Succesfully created commission', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/commission');
    }
  }, [isCreateError, isCreateSuccess]);

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Tambah Komisi
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Nama Komisi
                  </label>
                  <Input
                    type="text"
                    placeholder="E.g. Komisi A"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Deskripsi
                  </label>
                  <Input
                    type="text"
                    placeholder="E.g. Bidang Pemerintahan"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveCommission}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
