import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const commisionSlice = createSlice({
  name: 'commision',
  initialState,
  reducers: {
    setCommisions(state, action) {
      state.commisions = action.payload.data;
    },
  },
});

export const { setCommisions } = commisionSlice.actions;
export default commisionSlice.reducer;
export const selectCommisions = (state) => state.commision.commisions;
