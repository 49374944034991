import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Admin from '../../components/Layouts/Admin/Admin1';
import React from 'react';
import { useGetAspirationsQuery } from './aspirationApiSlice';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Button from '../../components/Elements/Button';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { NumericFormat } from 'react-number-format';

export default function AspirationList() {
  const history = useHistory();
  const {
    data: aspirations,
    isLoading,
    isError,
    error,
  } = useGetAspirationsQuery();

  function onClickDetail(id) {
    history.push(`/aspiration/${id}/detail`);
  }

  const columns = [
    {
      name: 'Aspirasi',
      selector: (row) => row.aspiration_proposal,
      sortable: true,
      cellExport: (row) => row.aspiration_proposal,
    },
    {
      name: 'Budget',
      selector: (row) => (
        <NumericFormat
          value={row.budget}
          displayType="text"
          thousandSeparator={true}
          prefix="Rp"
        />
      ),
      sortable: true,
      cellExport: (row) => row.budget,
    },
    {
      name: 'Anggota Dewan',
      selector: (row) => row.council_member,
      sortable: true,
      cellExport: (row) => row.council_member,
    },
    {
      name: 'Tipe Perencanaan',
      selector: (row) => row.planning_type,
      sortable: true,
      cellExport: (row) => row.planning_type,
    },
    {
      name: 'Tahun Perencanaan',
      selector: (row) => row.year_of_planning,
      sortable: true,
      cellExport: (row) => row.year_of_planning,
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <Button
          color="lightBlue"
          size="sm"
          onClick={() => onClickDetail(row.id)}
          children="Detail"
        />
      ),
    },
  ];

  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white text-blueGray-700">
        <div className="px-6 py-4 border-0">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h3 className="font-bold text-lg white">Aspirasi</h3>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <DataTableExtensions
            columns={columns}
            data={aspirations || []}
            filterPlaceholder="Cari Aspirasi"
            exportHeaders={true}
            print={false}
            fileName="Aspiration"
          >
            <DataTable
              columns={columns}
              data={aspirations || []}
              progressPending={isLoading}
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </div>
    </Admin>
  );
}
