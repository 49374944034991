import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import CardProfileCouncil from '../../components/Cards/Admin/CardProfileCouncil';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useGetAspirationQuery } from './aspirationApiSlice';
import { NumericFormat } from 'react-number-format';

export default function AspirationDetail() {
  const history = useHistory();
  const { aspirationId } = useParams();

  const [yearOfPlanning, setYearOfPlanning] = useState('');
  const [planningType, setPlanningType] = useState('');
  const [aspirationProposal, setAspirationProposal] = useState('');
  const [authority, setAuthority] = useState('');
  const [localGovernment, setLocalGovernment] = useState('');
  const [volume, setVolume] = useState('');
  const [unit, setUnit] = useState('');
  const [budget, setBudget] = useState('');
  const [village, setVillage] = useState('');
  const [locationDetail, setLocationDetail] = useState('');
  const [mission, setMission] = useState('');
  const [program, setProgram] = useState('');
  const [proposal, setProposal] = useState([]);
  const [councilMember, setCouncilMember] = useState([]);

  const { data: aspiration } = useGetAspirationQuery(aspirationId);
  useEffect(() => {
    if (aspiration) {
      setYearOfPlanning(aspiration.year_of_planning);
      setPlanningType(aspiration.planning_type);
      setAspirationProposal(aspiration.aspiration_proposal);
      setAuthority(aspiration.authority);
      setLocalGovernment(aspiration.local_government_organization);
      setVolume(aspiration.volume);
      setUnit(aspiration.unit);
      setBudget(aspiration.budget);
      setVillage(aspiration.village);
      setLocationDetail(aspiration.location_detail);
      setMission(aspiration.mission);
      setProgram(aspiration.program);
      setProposal(aspiration.proposal);
      setCouncilMember(aspiration.council_member);
    }
  }, [aspiration]);
  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <form>
                <div className="container mx-auto px-4">
                  <div className="flex flex-row flex-wrap justify-between text-center">
                    <h3 className="text-3xl font-semibold mt-4 mb-6">
                      Detail Aspirasi
                    </h3>
                  </div>
                  <div className="flex flex-wrap -mx-4">
                    <div className="px-4 pb-2 relative w-full lg:w-6/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Tahun Perencanaan
                      </label>
                      <Input
                        type="text"
                        placeholder="Tahun Perencanaan"
                        value={yearOfPlanning}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-6/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Jenis Perencanaan
                      </label>
                      <Input
                        type="text"
                        placeholder="Jenis Perencanaan"
                        value={planningType}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Aspirasi/Proposal
                      </label>
                      <Input
                        type="text"
                        placeholder="Aspirasi/Proposal"
                        value={aspirationProposal}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Kewenangan
                      </label>
                      <Input
                        type="text"
                        placeholder="Kewenangan"
                        value={authority}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        OPD
                      </label>
                      <Input
                        type="text"
                        placeholder="OPD"
                        value={localGovernment}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-6/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Volume
                      </label>
                      <Input
                        type="text"
                        placeholder="Volume"
                        value={volume}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-6/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Unit
                      </label>
                      <Input
                        type="text"
                        placeholder="Unit"
                        value={unit}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Anggaran
                      </label>
                      <div className="mb-3 pt-0">
                        <NumericFormat
                          className="px-3 py-2 text-sm border-blueGray-300 w-full placeholder-blueGray-200 text-blueGray-700 relative bg-white rounded-md outline-none focus:ring focus:ring-lightBlue-500 focus:ring-1 focus:border-lightBlue-500 border border-solid transition duration-200"
                          value={budget}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="Rp"
                        />
                      </div>
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Desa
                      </label>
                      <Input
                        type="text"
                        placeholder="Desa"
                        value={village}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Lokasi Detail
                      </label>
                      <Input
                        type="text"
                        placeholder="Lokasi Detail"
                        value={locationDetail}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Misi
                      </label>
                      <Input
                        type="text"
                        placeholder="Misi"
                        value={mission}
                        disabled
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Program
                      </label>
                      <Input
                        type="text"
                        placeholder="Program"
                        value={program}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-4">
                    <Button
                      color="blueGray"
                      size="sm"
                      onClick={() => history.push('/proposal')}
                      children="Kembali"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-4/12">
          <div className="px-4 py-5 flex-auto">
            <CardProfileCouncil
              name={councilMember?.user?.name}
              commission={councilMember?.commission?.name}
              faction={councilMember?.faction?.name}
              position={councilMember?.position?.name}
              constituency={councilMember?.constituency?.name}
            />
          </div>
        </div>
      </div>
    </Admin1>
  );
}
