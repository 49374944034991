const textProps = [
  {
    divider: true,
  },
  {
    title: 'Aspirator',
  },
  {
    icon: 'fas fa-tv',
    text: 'Dashboard',
    link: {
      href: '/dashboard',
    },
  },
  {
    icon: 'fas fa-tools',
    text: 'Usulan',
    link: {
      href: '/proposal/me',
    },
  },
];
export default textProps;
