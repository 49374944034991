import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers';
import { apiSlice } from './api/apiSlice';
import * as Sentry from '@sentry/react';

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    blacklist: ['api'],
  },
  reducer
);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  enhancers: [sentryReduxEnhancer],
  // .concat(logger),
});
export const persistor = persistStore(store);

// export const store = configureStore({
//   reducer: {
//     [apiSlice.reducerPath]: apiSlice.reducer,
//     auth: authReducer,
//     commission: commisioinReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//   devTools: true,
// });
