import { apiSlice } from '../../app/api/apiSlice';

export const constituencyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConstituencies: builder.query({
      query: () => ({
        url: '/constituencies',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Constituency',
                id,
              })),
              { type: 'Constituency', id: 'LIST' },
            ]
          : [],
    }),
    getConstituency: builder.query({
      query: (id) => `/constituency/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Constituency',
          id: arg,
        },
      ],
    }),
    createConstituency: builder.mutation({
      query: (body) => ({
        url: '/constituency',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Constituency', id: 'LIST' },
      ],
    }),
    updateConstituency: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/constituency/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Constituency', id: 'LIST' },
      ],
    }),
    deleteConstituency: builder.mutation({
      query: (id) => ({
        url: `/constituency/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Constituency', id: arg.id },
      ],
    }),

    // get constiutency by faction id
    getConstituencyByFactionId: builder.query({
      query: (id) => ({
        url: `/constituency/faction/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Constituency', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetConstituenciesQuery,
  useGetConstituencyQuery,
  useCreateConstituencyMutation,
  useUpdateConstituencyMutation,
  useDeleteConstituencyMutation,
  useGetConstituencyByFactionIdQuery,
} = constituencyApiSlice;
