import { apiSlice } from '../../app/api/apiSlice';

export const fractionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFractions: builder.query({
      query: () => '/factions',
      transformResponse: (responseData) => {
        return responseData.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({ type: 'Fraction', id })),
        { type: 'Fraction', id: 'LIST' },
      ],
    }),
    getFractionById: builder.query({
      query: (fractionId) => ({
        url: `/faction/${fractionId}`,
        method: 'GET',
      }),
      transformResponse: (responseData) => {
        return responseData.data;
      },
      providesTags: (result, error, arg) => [{ type: 'Fraction', id: arg }],
    }),
    addNewFraction: builder.mutation({
      query: (newFraction) => ({
        url: '/faction',
        method: 'POST',
        body: { ...newFraction },
      }),
      invalidatesTags: [{ type: 'Fraction', id: 'LIST' }],
    }),
    updateFraction: builder.mutation({
      query: ({ fractionId, ...updateFraction }) => ({
        url: `/faction/${fractionId}`,
        method: 'PUT',
        body: updateFraction,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Fraction', id: arg.id },
      ],
    }),
    deleteFraction: builder.mutation({
      query: (fraction) => ({
        url: `/faction/${fraction.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Fraction', id: arg.id },
      ],
    }),
    //   get Faction by Commission id
    getFractionByCommissionId: builder.query({
      query: (commissionId) => ({
        url: `/factions/commission/${commissionId}`,
        method: 'GET',
      }),
      transformResponse: (responseData) => {
        return responseData.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Fraction', id })),
              { type: 'Fraction', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const {
  useGetFractionsQuery,
  useGetFractionByIdQuery,
  useAddNewFractionMutation,
  useUpdateFractionMutation,
  useDeleteFractionMutation,
  useGetFractionByCommissionIdQuery,
} = fractionApiSlice;
