/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../features/auth/authSlice';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DropdownUser({ image, items, name }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const [transformOrigin, setTransformOrigin] = React.useState(
    'origin-top-left'
  );
  const [menuClasses, setMenuClasses] = React.useState('');
  const [animating, setAntimating] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const origins = {
    'top-start': 'origin-bottom-left',
    'top-end': 'origin-bottom-right',
    'bottom-start': 'origin-top-left',
    'bottom-end': 'origin-top-right',
    'right-start': 'origin-top-left',
    'right-end': 'origin-top-left',
    'left-start': 'origin-top-right',
    'left-end': 'origin-top-right',
    bottom: 'origin-top',
    top: 'origin-bottom',
    left: 'origin-right',
    right: 'origin-left',
  };
  const startAnimation = () => {
    if (!animating) {
      setAntimating(true);
      if (dropdownPopoverShow) {
        setMenuClasses('');
        setTimeout(() => {
          setDropdownPopoverShow(false);
        }, 310);
      } else {
        const popperObject = createPopper(
          btnDropdownRef.current,
          popoverDropdownRef.current,
          {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-150, 5],
                },
              },
            ],
          }
        );
        setTransformOrigin(origins[popperObject.state.placement]);
        setDropdownPopoverShow(true);
        setTimeout(() => {
          setMenuClasses('opacity-100 scale-100 ');
        }, 10);
      }
      setTimeout(() => {
        setAntimating(false);
      }, 350);
    }
  };
  // get auth user from redux store
  const { auth } = useSelector((state) => state);

  const onClickLogout = () => {
    dispatch(logOut());
    history.push('/login');
  };

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <span className="sr-only">Open user menu</span>
          <img className="w-12 h-12 rounded-full" src={image} alt="" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="text-sm px-3 py-2 block w-full whitespace-nowrap bg-transparent transition-all duration-100">
            <strong>{auth ? auth?.fullName : 'Anonymous'}</strong>
          </div>
          <div className="h-0 my-2 border border-solid border-blueGray-100" />
          <Menu.Item>
            {({ active }) => (
              <Link
                to="/profile"
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Profil
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                to="/change-password"
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Ubah Password
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <div
                onClick={onClickLogout}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                )}
              >
                Keluar
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

DropdownUser.defaultProps = {
  items: [],
};

DropdownUser.propTypes = {
  image: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // array of properties to pass to the link object
      // if you pass a prop named to, the link will be
      // generated using Link from react-router-dom
      // also, if you pass a prop named disabled
      // the link will be generated as a simple text
      PropTypes.object,
      PropTypes.shape({
        divider: true,
      }),
    ])
  ),
  name: PropTypes.string,
};
