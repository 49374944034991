import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  useGetPlanningTypeQuery,
  useUpdatePlanningTypeMutation,
} from './planningTypeApiSlice';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function PlanningTypeEdit() {
  const history = useHistory();
  const params = useParams();
  const [name, setName] = useState('');
  const { data: planningType, isSuccess } = useGetPlanningTypeQuery(
    params.planningTypeId
  );
  useEffect(() => {
    if (isSuccess) {
      setName(planningType.name);
    }
  }, [isSuccess, planningType]);
  const [
    updatePlanningType,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdatePlanningTypeMutation();
  const onSaveClicked = async () => {
    try {
      await updatePlanningType({ id: params.planningTypeId, name });
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success('Successfully updated planning type', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/planning-type');
    } else if (isUpdateError) {
      toast.error(`Error updating planning type ${updateError?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isUpdateSuccess, isUpdateError, updateError]);

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Ubah Jenis Perencanaan
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Nama Jenis Perencanaan
                  </label>
                  <Input
                    type="text"
                    placeholder="Nama Jenis Perencanaan"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
