import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAuth = ({ role, children }) => {
  const history = useHistory();
  const { auth } = useSelector((state) => state);
  if (!auth.token) {
    history.push('/login');
    return null;
  }
  return role.includes(auth.role) ? (
    children
  ) : auth.token ? (
    <Redirect to="/unauthorized" />
  ) : (
    <Redirect to="/login" />
  );
};
export default RequireAuth;
