import Admin from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import { useHistory } from 'react-router-dom';
import {
  useActiveCouncilMembersMutation,
  useDeleteCouncilMemberMutation,
  useGetCouncilMembersQuery,
  useNonActiveCouncilMembersMutation,
} from './councilMemberApiSlice';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import * as Sentry from '@sentry/react';

export default function CouncilMemberList() {
  const history = useHistory();
  const { data: councilMembers, isLoading } = useGetCouncilMembersQuery();
  const [
    deleteCouncilMember,
    { isSuccess: isSuccessDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteCouncilMemberMutation();

  function onClickEdit(id) {
    history.push(`/council-member/${id}/edit`);
  }

  const onClickDelete = async (id) => {
    try {
      await deleteCouncilMember(id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (isSuccessDelete) {
      toast.success('Successfully deleted council member', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isErrorDelete) {
      toast.error(`Error deleting council member ${errorDelete?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccessDelete, isErrorDelete, errorDelete]);

  const [
    activeCouncilMembers,
    { isSuccess: isSuccessActive, isError: isErrorActive, error: errorActive },
  ] = useActiveCouncilMembersMutation();
  useEffect(() => {
    if (isSuccessActive) {
      toast.success('Successfully activated council member', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isErrorActive) {
      toast.error(
        `Error activating council member ${errorActive?.data?.data}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }, [isSuccessActive, isErrorActive, errorActive]);

  const onClickActive = async (id) => {
    try {
      await activeCouncilMembers(id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const [
    nonActiveCouncilMembers,
    {
      isSuccess: isSuccessNonActive,
      isError: isErrorNonActive,
      error: errorNonActive,
    },
  ] = useNonActiveCouncilMembersMutation();
  useEffect(() => {
    if (isSuccessNonActive) {
      toast.success('Successfully deactivated council member', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isErrorNonActive) {
      toast.error(
        `Error deactivating council member ${errorNonActive?.data?.data}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }, [isSuccessNonActive, isErrorNonActive, errorNonActive]);

  const onClickNonActive = async (id) => {
    try {
      await nonActiveCouncilMembers(id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const columns = [
    {
      name: 'Nama Anggota Dewan',
      selector: (row) => row.user?.name,
      sortable: true,
      cellExport: (row) => row.user?.name,
    },
    {
      name: 'Komisi',
      selector: (row) => row.commission?.name,
      sortable: true,
      cellExport: (row) => row.commission?.name,
    },
    {
      name: 'Fraksi',
      selector: (row) => row.faction?.name,
      sortable: true,
      cellExport: (row) => row.faction?.name,
    },
    {
      name: 'Jabatan',
      selector: (row) => row.position?.name,
      sortable: true,
      cellExport: (row) => row.position?.name,
    },
    {
      name: 'Dapil',
      selector: (row) => row.constituency?.name,
      sortable: true,
      cellExport: (row) => row.constituency?.name,
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <div className="flex flex-wrap px-1 py-1">
          <Button
            size="sm"
            type="button"
            color="orange"
            children="Ubah"
            onClick={() => onClickEdit(row.id)}
          />
          {row.is_active ? (
            <Button
              size="sm"
              type="button"
              color="amber"
              children="Non-Aktif"
              onClick={() => onClickNonActive(row.id)}
            />
          ) : (
            <Button
              size="sm"
              type="button"
              color="emerald"
              children="Aktifkan"
              onClick={() => onClickActive(row.id)}
            />
          )}

          <Button
            size="sm"
            type="button"
            color="red"
            children="Hapus"
            onClick={() => onClickDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white text-blueGray-700">
        <div className="px-6 py-4 border-0">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h3 className="font-bold text-lg white">Anggota Dewan</h3>
            <Button
              type="button"
              color="lightBlue"
              children="Tambah Anggota Dewan"
              size="sm"
              onClick={() => history.push('/council-member/create')}
            />
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <DataTableExtensions
            columns={columns}
            data={councilMembers || []}
            filterPlaceholder="Cari Anggota Dewan"
            exportHeaders={true}
            print={false}
            fileName="Anggota Dewan"
          >
            <DataTable
              columns={columns}
              data={councilMembers || []}
              progressPending={isLoading}
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </div>
    </Admin>
  );
}
