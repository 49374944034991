import { apiSlice } from '../../app/api/apiSlice';

export const programApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: ({ localGovernmentId, missionId }) => ({
        url: `/programs/local-government/${localGovernmentId}/mission/${missionId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Program',
                id,
              })),
              { type: 'Program', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const { useGetProgramsQuery } = programApiSlice;
