import React from 'react';

const textProps = {
  color: 'transparent',
  type: 'absolute',
  logoText: 'e-Asmara',
  logoLink: { to: '/' },
  socials: [
    {
      icon: 'facebook',
      text: 'Share on Facebook',
      link: 'https://www.facebook.com/sekretariatdprdpemalang/',
    },
    {
      icon: 'twitter',
      text: 'Tweet on Twitter',
      link: 'https://twitter.com/DPRD_Pemalang',
    },
  ],
  // rightLinks: [
  //   {
  //     href: '#aspirasi',
  //     children: 'Aspirasi',
  //   },
  // ],
};
export default textProps;
