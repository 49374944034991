import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import Select from 'react-select';
import { useGetCommissionsQuery } from '../commission/commissionApiSlice';
import {
  useGetCouncilMemberQuery,
  useUpdateCouncilMemberMutation,
} from './councilMemberApiSlice';
import { toast } from 'react-toastify';
import { useGetPositionsQuery } from '../position/positionApiSlice';
import { useGetConstituenciesQuery } from '../constituency/constituencyApiSlice';
import { useGetFractionsQuery } from '../fraction/fractionApiSlice';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';

export default function CouncilMemberEdit() {
  const { councilMemberId } = useParams();
  const history = useHistory();
  const [commissionId, setCommissionId] = useState('');
  const [constituencyId, setConstituencyId] = useState('');
  const [fractionId, setFractionId] = useState('');
  const [fullName, setFullName] = useState('');
  const [position, setPosition] = useState('');
  const commissionOptions = [];
  const constituencyOptions = [];
  const positionOptions = [];
  const fractionOptions = [];

  const {
    data: councilMember,
    isSuccess: isSuccessCouncilMember,
  } = useGetCouncilMemberQuery(councilMemberId);
  useEffect(() => {
    if (isSuccessCouncilMember) {
      setCommissionId(councilMember.commission?.id);
      setConstituencyId(councilMember.constituency?.id);
      setFractionId(councilMember.faction?.id);
      setFullName(councilMember.user?.name);
      setPosition(councilMember.position?.id);
    }
  }, [isSuccessCouncilMember, councilMember]);

  const { data: commissions } = useGetCommissionsQuery();
  if (commissions) {
    commissions.forEach((commission) => {
      commissionOptions.push({ value: commission.id, label: commission.name });
    });
  }

  const { data: constituencies } = useGetConstituenciesQuery();
  if (constituencies) {
    constituencies.forEach((constituency) => {
      constituencyOptions.push({
        value: constituency.id,
        label: constituency.name,
      });
    });
  }

  const { data: positions } = useGetPositionsQuery();
  if (positions) {
    positions.forEach((position) => {
      positionOptions.push({ value: position.id, label: position.name });
    });
  }

  const {
    data: fractions,
    isSuccess: isSuccessFractions,
  } = useGetFractionsQuery();
  if (isSuccessFractions) {
    fractions.forEach((fraction) => {
      fractionOptions.push({ value: fraction.id, label: fraction.name });
    });
  }

  const [
    updateCouncilMember,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: errorUpdate },
  ] = useUpdateCouncilMemberMutation();
  useEffect(() => {
    if (isUpdateError) {
      toast.error(`Error updating council member ${errorUpdate?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isUpdateSuccess) {
      toast.success('Successfully updated council member', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/council-member');
    }
  }, [isUpdateSuccess, isUpdateError, errorUpdate, history]);
  const onSaveClicked = async () => {
    try {
      await updateCouncilMember({
        id: councilMemberId,
        commission_id: commissionId,
        constituency_id: constituencyId,
        faction_id: fractionId,
        position,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Edit Anggota Dewan
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Nama Lengkap
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={fullName}
                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
                    placeholder="Nama Lengkap"
                    disabled={true}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Komisi
                  </label>
                  <Select
                    placeholder="Pilih Komisi"
                    name="commission"
                    options={commissionOptions}
                    onChange={(value) => setCommissionId(value.value)}
                    value={commissionOptions.find(
                      (option) => option.value === commissionId
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Dapil
                  </label>
                  <Select
                    placeholder="Pilih Dapil"
                    name="commission"
                    options={constituencyOptions}
                    onChange={(value) => setConstituencyId(value.value)}
                    value={constituencyOptions.find(
                      (option) => option.value === constituencyId
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Fraksi
                  </label>
                  <Select
                    placeholder="Pilih Fraksi"
                    name="fraction"
                    options={fractionOptions}
                    onChange={(value) => setFractionId(value.value)}
                    value={fractionOptions.find(
                      (option) => option.value === fractionId
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Jabatan
                  </label>
                  <Select
                    placeholder="Pilih Jabatan"
                    name="position"
                    options={positionOptions}
                    onChange={(value) => setPosition(value.value)}
                    value={positionOptions.find(
                      (option) => option.value === position
                    )}
                  />
                </div>
              </div>

              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
