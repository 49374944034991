import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import React, { useEffect, useState } from 'react';
import Input from '../../components/Elements/Input';
import Admin from '../../components/Layouts/Admin/Admin1';
import Radio from '../../components/Elements/Radio';
import Select from 'react-select';
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from './userApiSlice';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function Profile() {
  const history = useHistory();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [identityNumber, setIdentityNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [religion, setReligion] = useState('');
  const [job, setJob] = useState('');
  const [documentIdentity, setDocumentIdentity] = useState('');

  const religionOptions = [
    { value: 'Islam', label: 'Islam' },
    { value: 'Kristen', label: 'Kristen' },
    { value: 'Katolik', label: 'Katolik' },
    { value: 'Hindu', label: 'Hindu' },
    { value: 'Budha', label: 'Budha' },
    { value: 'Konghucu', label: 'Konghucu' },
  ];

  const {
    data: user,
    isLoading,
    isError,
    isSuccess: isSusccessGetUserProfile,
  } = useGetUserProfileQuery();

  const [
    updateUserProfile,
    {
      isSuccess: isSusccessUpdateUserProfile,
      isError: isErrorUpdateUserProfile,
    },
  ] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (isError) {
      toast.error('Gagal mengambil data user', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/dashboard');
    } else if (isSusccessGetUserProfile) {
      setFullName(user.full_name ? user.full_name : '');
      setEmail(user.email ? user.email : '');
      setIdentityNumber(user.identity_number ? user.identity_number : '');
      setPhoneNumber(user.phone_number ? user.phone_number : '');
      setPlaceOfBirth(user.place_of_birth ? user.place_of_birth : '');
      setDateOfBirth(
        user.date_of_birth ? user.date_of_birth.substring(0, 10) : ''
      );
      setAddress(user.address ? user.address : '');
      setGender(user.gender ? user.gender : '');
      setReligion(user.religion ? user.religion : '');
      setJob(user.job ? user.job : '');
      setDocumentIdentity(user.document_identity ? user.document_identity : '');
    }
  }, [isSusccessGetUserProfile, user, isError]);

  useEffect(() => {
    if (isSusccessUpdateUserProfile) {
      toast.success('Berhasil mengubah data user', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isErrorUpdateUserProfile) {
      toast.error('Gagal mengubah data user', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSusccessUpdateUserProfile, isErrorUpdateUserProfile]);

  const onSaveUserClicked = async () => {
    try {
      // identitiy number type data is number
      const identityNumberInt = parseInt(identityNumber);
      const payload = {
        identity_number: identityNumberInt,
        phone_number: phoneNumber,
        place_of_birth: placeOfBirth,
        date_of_birth: dateOfBirth,
        address,
        gender,
        religion,
        job,
      };
      await updateUserProfile(payload);
    } catch (err) {
      Sentry.captureException('Failed to save the user: ', err);
    }
  };

  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 p-6 pb-0">
          <div className="text-center flex justify-between items-center">
            <h6 className="text-xl font-bold mb-0">Pengaturan Pengguna</h6>
            <Button
              children="Simpan"
              size="sm"
              color="lightBlue"
              onClick={onSaveUserClicked}
            />
          </div>
        </div>
        <div className="flex-auto px-6 pb-6 pt-0">
          <form>
            <div>
              <h6 className="mt-6 mb-2 font-bold">Detail Pengguna</h6>
              <hr className="mb-6 border-b-1 border-blueGray-200" />
              <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Nama Lengkap
                    </label>
                    <Input
                      type="text"
                      placeholder="E.g. Smith"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <Input
                      type="email"
                      placeholder="E.g. name@email.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Nomor Identitas
                    </label>
                    <Input
                      type="number"
                      placeholder="E.g. 3327 1234 1234 1234"
                      value={identityNumber}
                      onChange={(e) => setIdentityNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Jenis Kelamin
                    </label>
                    <div>
                      <Radio
                        label="Laki-laki"
                        name="gender"
                        value="Male"
                        checked={gender === 'Male'}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <Radio
                        label="Perempuan"
                        name="gender"
                        value="Female"
                        checked={gender === 'Female'}
                        onChange={(e) => setGender(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Agama
                    </label>
                    <Select
                      options={religionOptions}
                      placeholder="Pilih Agama"
                      value={religionOptions.find((option) => {
                        return option.value === religion;
                      })}
                      onChange={(e) => setReligion(e.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Pekerjaan
                    </label>
                    <Input
                      type="text"
                      placeholder="E.g. Software Engineer"
                      value={job}
                      onChange={(e) => setJob(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Tempat Lahir
                    </label>
                    <Input
                      type="text"
                      placeholder="E.g. Jakarta"
                      value={placeOfBirth}
                      onChange={(e) => setPlaceOfBirth(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-6/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Tanggal Lahir
                    </label>
                    <Input
                      type="date"
                      placeholder="E.g. 01/01/2000"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h6 className="mt-6 mb-2 font-bold">Kontak</h6>
              <hr className="mb-6 border-b-1 border-blueGray-200" />
              <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4 lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Alamat
                    </label>
                    <Input
                      type="text"
                      placeholder="E.g. Jl. Raya Kedungkandang"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full px-4 lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                      htmlFor="grid-password"
                    >
                      Nomor Telepon
                    </label>
                    <Input
                      type="number"
                      placeholder="E.g. 08123456789"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin>
  );
}
