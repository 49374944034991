import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  useGetProposalByCouncilMemberDetailQuery,
  useRejectProposalMutation,
} from './proposalApiSlice';
import { useParams } from 'react-router';
import CardProfile from '../../components/Cards/Admin/CardProfile';
import { headerstatcards } from '../../global/propAdmin';
import Button from '../../components/Elements/Button';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

export default function ProposalDetail() {
  const { proposalId } = useParams();
  const history = useHistory();
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [element, setElement] = useState('');
  const [commission, setCommission] = useState('');
  const [faction, setFaction] = useState('');
  const [councilMember, setCouncilMember] = useState('');
  const [status, setStatus] = useState('');
  const [proposalDocument, setProposalDocument] = useState('');
  const [user, setUser] = useState([]);

  const { data: proposal } = useGetProposalByCouncilMemberDetailQuery(
    proposalId
  );
  useEffect(() => {
    if (proposal) {
      setSubject(proposal.subject);
      setDescription(proposal.description);
      setElement(proposal.element);
      setCommission(proposal.commission);
      setFaction(proposal.faction);
      setCouncilMember(proposal.council_member_name);
      setStatus(proposal.status);
      setProposalDocument(proposal.proposal_document);
      setUser(proposal.user);
    }
  }, [proposal]);

  function onClickCreateAspirasi(proposalId) {
    history.push(`/my-aspiration/${proposalId}/create`);
  }

  const [
    rejectProposal,
    { isSuccess: isSuccessReject, isError: isErrorReject, error: errorReject },
  ] = useRejectProposalMutation();

  const onClickRejectProposal = async () => {
    try {
      await rejectProposal(proposalId);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isSuccessReject) {
      toast.success('Proposal berhasil ditolak', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/proposal');
    }
    if (isErrorReject) {
      toast.error(errorReject?.data?.data, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccessReject, isErrorReject, errorReject]);

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="container mx-auto px-4">
                <div className="flex flex-row flex-wrap justify-between text-center">
                  <h3 className="text-3xl font-semibold mt-4 mb-6">
                    Detail Usulan
                  </h3>
                  <span className="absolute top-1 right-0 text-lightBlue-500 bg-lightBlue-200 align-middle text-sm font-bold inline-block py-2 uppercase uppercase last:mr-0 mr-1 leading-tight rounded px-3">
                    {status}
                  </span>
                </div>
                <div className="flex flex-wrap -mx-4">
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Usulan
                    </label>
                    <Input
                      type="text"
                      placeholder="Subject"
                      value={subject}
                      disabled
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Deskripsi
                    </label>
                    <Input
                      type="text"
                      placeholder="Deskripsi"
                      value={description}
                      disabled
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Element
                    </label>
                    <Input
                      type="text"
                      placeholder="Element"
                      value={element}
                      disabled
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Komisi
                    </label>
                    <Input
                      type="text"
                      placeholder="Komisi"
                      value={commission}
                      disabled
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Fraksi
                    </label>
                    <Input
                      type="text"
                      placeholder="Fraksi"
                      value={faction}
                      disabled
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Nama Anggota Dewan
                    </label>
                    <Input
                      type="text"
                      placeholder="Nama Anggota Dewan"
                      value={councilMember}
                      disabled
                    />
                  </div>
                </div>
                {proposalDocument && (
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Proposal
                    </label>
                    <Button
                      color="white"
                      size="lg"
                      onClick={() => window.open(proposalDocument, '_blank')}
                    >
                      <i className="fas fa-file-pdf" />
                    </Button>
                  </div>
                )}
                <div className="flex flex-wrap -mx-4">
                  <Button
                    color="blueGray"
                    size="sm"
                    onClick={() => history.push('/proposal')}
                    children="Kembali"
                  />
                  {(status === 'reviewed' || status === 'pending') && (
                    <Button
                      color="lightBlue"
                      size="sm"
                      onClick={() => onClickCreateAspirasi(proposalId)}
                      children="Tambah ke Aspirasi"
                    />
                  )}
                  {(status === 'reviewed' || status === 'pending') && (
                    <Button
                      color="red"
                      size="sm"
                      onClick={() => onClickRejectProposal()}
                      children="Tolak"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-4/12">
          <div className="px-4 py-5 flex-auto">
            <CardProfile
              image={require('assets/img/team-2-800x800.jpg').default}
              name={user.full_name}
              identityNumber={user.identity_number}
              gender={user.gender}
              email={user.email}
              phone={user.phone_number}
              address={user.address}
              dateOfBirth={user.date_of_birth}
              placeOfBirth={user.place_of_birth}
            />
          </div>
        </div>
      </div>
    </Admin1>
  );
}
