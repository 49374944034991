import { apiSlice } from '../../app/api/apiSlice';

export const authorityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get Authorities
    getAuthorities: builder.query({
      query: () => ({
        url: '/authorities',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      // providesTags if result is not empty
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Authority',
                id,
              })),
              { type: 'Authority', id: 'LIST' },
            ]
          : [],
    }),
    getAuthority: builder.query({
      query: (id) => `/authority/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Authority',
          id: arg,
        },
      ],
    }),
    // create Authority
    createAuthority: builder.mutation({
      query: (body) => ({
        url: '/authority',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Authority', id: 'LIST' }],
    }),
    // update Authority
    updateAuthority: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/authority/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Authority', id: 'LIST' }],
    }),
    // delete Authority
    deleteAuthority: builder.mutation({
      query: (id) => ({
        url: `/authority/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Authority', id: arg.id },
      ],
    }),
  }),
});
export const {
  useGetAuthoritiesQuery,
  useGetAuthorityQuery,
  useCreateAuthorityMutation,
  useUpdateAuthorityMutation,
  useDeleteAuthorityMutation,
} = authorityApiSlice;
