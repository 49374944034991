const textProps = [
  {
    divider: true,
  },
  {
    title: 'Operator',
  },
  {
    icon: 'fas fa-tv',
    text: 'Dashboard',
    link: {
      href: '/dashboard',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Aspirasi',
    link: {
      href: '/aspirations',
    },
  },
  {
    divider: true,
  },
];
export default textProps;
