/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

// components

export default function CardDashboardProfile({
  image,
  name,
  identityNumber,
  email,
  phone,
  address,
  placeOfBirth,
  dateOfBirth,
  gender,
  religion,
  job,
}) {
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg mt-16">
      <div className="px-6">
        <div className="flex flex-wrap justify-center">
          <div className="w-full flex justify-center">
            <div className="relative">
              <img
                alt="..."
                src={image}
                className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16  max-w-100-px"
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <h3 className="text-xl font-bold leading-normal mb-2">
            {gender === 'Male' ? (
              <i className="fas fa-male" />
            ) : gender === 'Female' ? (
              <i className="fas fa-female" />
            ) : (
              <i className="fas fa-genderless" />
            )}
            &nbsp;
            {name}
          </h3>
          <h4 className="text-sm font-bold leading-normal mb-2">
            {identityNumber}
          </h4>
          {placeOfBirth && dateOfBirth && (
            <div className="text-xs mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-baby mr-2 text-blueGray-400 opacity-75" />
              {`${placeOfBirth}, `}
              <Moment format="DD MMMM YYYY">{dateOfBirth}</Moment>
            </div>
          )}
          {phone && (
            <div className="text-xs mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-phone mr-2 text-blueGray-400 opacity-75" />
              {phone}
            </div>
          )}
          {email && (
            <div className="text-xs mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-envelope mr-2 text-blueGray-400 opacity-75" />
              {email}
            </div>
          )}
          {address && (
            <div className="text-xs mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-map-marker-alt mr-2 text-blueGray-400 opacity-75" />
              {address}
            </div>
          )}
          {job && (
            <div className="text-xs mt-0 mb-2 text-blueGray-400 font-bold uppercase">
              <i className="fas fa-briefcase mr-2 text-blueGray-400 opacity-75" />
              {job}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CardDashboardProfile.defaultProps = {
  image: require('assets/img/team-2-800x800.jpg').default,
};

CardDashboardProfile.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  identityNumber: PropTypes.number,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  placeOfBirth: PropTypes.string,
  dateOfBirth: PropTypes.string,
  gender: PropTypes.string,
  religion: PropTypes.string,
  job: PropTypes.string,
};
