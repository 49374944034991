import Admin from '../../components/Layouts/Admin/Admin1.js';
// texts as props
import sidebar from '_texts/admin/sidebar/sidebar.js';
import navbarsearchusersettings2 from '_texts/admin/navbars/navbarsearchusersettings2.js';
import footeradmin from '_texts/admin/footers/footeradmin.js';
import React from 'react';

export default function Dashboard() {
  const headerStats = {
    color: 'lightBlue',
    // cards: [
    //   { ...cardstatsmini },
    //   {
    //     statSubtitle: 'NEW USERS',
    //     statTitle: '2,356',
    //     // statArrow: 'down',
    //     // statPercent: '3.48',
    //     // statPercentColor: 'text-red-500',
    //     // statDescription: 'Since last week',
    //     statIconName: 'fas fa-chart-pie',
    //     statIconColor: 'bg-orange-500',
    //   },
    //   {
    //     statSubtitle: 'SALES',
    //     statTitle: '924',
    //     statArrow: 'down',
    //     statPercent: '1.10',
    //     statPercentColor: 'text-orange-500',
    //     statDescription: 'Since yesterday',
    //     statIconName: 'fas fa-users',
    //     statIconColor: 'bg-pink-500',
    //   },
    //   // {
    //   //   statSubtitle: 'PERFORMANCE',
    //   //   statTitle: '49,65%',
    //   //   statArrow: 'up',
    //   //   statPercent: '12',
    //   //   statPercentColor: 'text-emerald-500',
    //   //   statDescription: 'Since last month',
    //   //   statIconName: 'fas fa-percent',
    //   //   statIconColor: 'bg-lightBlue-500',
    //   // },
    // ],
  };
  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerStats}
      footer={footeradmin}
    >
      <div className="relative w-full h-600-px bg-white text-center flex flex-col items-center justify-center content-center border border-solid border-blueGray-200 rounded p-40">
        <h1 className="text-6xl font-normal leading-normal mt-0 mb-2">
          Selamat Datang
        </h1>
        <p>{process.env.REACT_APP_DESCRIPTION}</p>
      </div>
    </Admin>
  );
}
