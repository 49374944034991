import { apiSlice } from './api/apiSlice';
import authReducer from '../features/auth/authSlice';
import commisioinReducer from '../features/commission/commisionSlice';
import fractionReducer from '../features/fraction/fractionSlice';
import { combineReducers } from '@reduxjs/toolkit';

const reducer = combineReducers({
  auth: authReducer,
  commision: commisioinReducer,
  fraction: fractionReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default reducer;
