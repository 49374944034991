import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useAddNewFractionMutation } from './fractionApiSlice';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

export default function FractionAdd() {
  const [
    addNewFraction,
    { isLoading, isSuccess, isError, error },
  ] = useAddNewFractionMutation();
  const history = useHistory();
  const [name, setName] = useState('');
  const onSaveFractionClicked = async () => {
    try {
      await addNewFraction({ name }).unwrap();
    } catch (err) {
      Sentry.captureException('Failed to save fraction: ', err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Fraction saved successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setName('');
      history.push('/fraction');
    }
  }, [isSuccess, history]);

  useEffect(() => {
    if (isError) {
      toast.error(`Error saving fraction ${error?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isError, error]);

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Tambah Fraksi
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Nama Fraksi
                  </label>
                  <Input
                    type="text"
                    placeholder="Nama Fraksi"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveFractionClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
