import Auth1 from '../../components/Layouts/Auth/Auth1';
import navbarlinks from '../../_texts/auth/navbarlinks';
import footerauth from '../../_texts/auth/footers/footerauth';
import Button from '../../components/Elements/Button';
import { useHistory } from 'react-router-dom';
import { useVerifyEmailQuery } from './authApiSlice';
import { useParams } from 'react-router';

export default function VerifyEmail() {
  const { token } = useParams();
  const history = useHistory();

  const { data, isSuccess, isError, error } = useVerifyEmailQuery(token);

  function toLogin() {
    history.push('/login');
  }

  return (
    <Auth1
      image={require('assets/img/illustrations/reset.svg').default}
      navbar={navbarlinks}
      footer={footerauth}
    >
      <div className="container mx-auto px-4">
        <div className="justify-center flex flex-wrap -mx-4">
          <div className="px-4 relative w-full lg:w-5/12 w-full md:w-8/12">
            <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
              <div className="pb-6 rounded-t-lg overflow-hidden">
                <img
                  src={require('assets/img/illustrations/linth3.svg').default}
                  alt="..."
                  className="w-full rounded-t-lg"
                  style={{ marginTop: '-46%' }}
                />
              </div>
              {isSuccess && (
                <div className="lg:p-10 px-4 py-4 flex-auto rounded-b-lg">
                  <h4 className="text-2xl font-bold text-center">
                    Selamat Datang
                  </h4>
                  <div className="text-center text-blueGray-500 mb-5 mt-1">
                    <p>Akun Anda telah diverifikasi</p>
                  </div>

                  <div className="text-center mt-6">
                    <Button
                      color="lightBlue"
                      children="Masuk App"
                      onClick={() => toLogin()}
                    />
                  </div>
                </div>
              )}
              {isError && (
                <div className="lg:p-10 px-4 py-4 flex-auto rounded-b-lg">
                  <h4 className="text-2xl font-bold text-center">
                    Verifikasi Gagal
                  </h4>
                  <div className="text-center text-blueGray-500 mb-5 mt-1">
                    <p>{error?.data?.data}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Auth1>
  );
}
