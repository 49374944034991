const textProps = [
  {
    divider: true,
  },
  {
    title: 'Admin',
  },
  {
    icon: 'fas fa-tv',
    text: 'Dashboard',
    link: {
      href: '/dashboard',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Manajemen Pengguna',
    link: {
      href: '/user',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Anggota Dewan',
    link: {
      href: '/council-member',
    },
  },
  {
    divider: true,
  },
  {
    title: 'Master Data',
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Komisi',
    link: {
      href: '/commission',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Fraksi',
    link: {
      href: '/fraction',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Dapil',
    link: {
      href: '/constituency',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'OPD',
    link: {
      href: '/local-government',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Element',
    link: {
      href: '/element',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Satuan',
    link: {
      href: '/unit',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Tipe Perencanaan',
    link: {
      href: '/planning-type',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Jabatan',
    link: {
      href: '/position',
    },
  },
  {
    icon: 'fas fa-map-marked',
    text: 'Kewenangan',
    link: {
      href: '/authority',
    },
  },

  {
    divider: true,
  },
];
export default textProps;
