import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useCreateProposalMutation } from './proposalApiSlice';
import { useHistory } from 'react-router-dom';
import { useGetElementsQuery } from '../element/elementApiSlice';
import { useGetCouncilMemberByFactionIdConstituencyIdQuery } from '../councilMember/councilMemberApiSlice';
import { useGetFractionsQuery } from '../fraction/fractionApiSlice';
import { useGetConstituencyByFactionIdQuery } from '../constituency/constituencyApiSlice';
import * as Sentry from '@sentry/react';
import {
  useGetDistrictsByConstituencyIdQuery,
  useGetVillagesQuery,
} from '../region/regionApiSlice';

export default function ProposalCreate() {
  const history = useHistory();
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [elementId, setElementId] = useState('');
  const [factionId, setFactionId] = useState(null);
  const [constituencyId, setConstituencyId] = useState(null);
  const [councilMemberId, setCouncilMemberId] = useState('');
  const [villageId, setVillageId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [locationDetail, setLocationDetail] = useState('');
  const [councilMemberOptions, setCouncilMemberOptions] = useState([]);
  const [constituencyOptions, setConstituencyOptions] = useState([]);
  const elementOptions = useGetElementsQuery().data?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
  const factionOptions = useGetFractionsQuery().data?.map((fraction) => ({
    value: fraction.id,
    label: fraction.name,
  }));
  const [districtOptions, setDistrictOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);

  const {
    data: constituencyData,
    isSuccess: isConstituencySuccess,
  } = useGetConstituencyByFactionIdQuery(factionId);
  useEffect(() => {
    if (isConstituencySuccess) {
      setConstituencyOptions(
        constituencyData?.map((constituency) => ({
          value: constituency.id,
          label: constituency.name,
        }))
      );
    }
  }, [isConstituencySuccess, constituencyData]);

  const {
    data: councilMember,
    isSuccess: isCouncilMemberSuccess,
  } = useGetCouncilMemberByFactionIdConstituencyIdQuery({
    factionId,
    constituencyId,
  });
  useEffect(() => {
    if (isCouncilMemberSuccess) {
      setCouncilMemberOptions(
        councilMember?.map((councilMember) => ({
          value: councilMember.id,
          label: councilMember.user.name,
        }))
      );
    }
  }, [isCouncilMemberSuccess, councilMember]);

  const {
    data: districtData,
    isSuccess: isDistrictSuccess,
  } = useGetDistrictsByConstituencyIdQuery(constituencyId);
  useEffect(() => {
    if (isDistrictSuccess) {
      setDistrictOptions(
        districtData?.map((district) => ({
          value: district.id,
          label: district.name,
        }))
      );
    }
  }, [isDistrictSuccess, districtData]);

  const {
    data: villageData,
    isSuccess: isVillageSuccess,
  } = useGetVillagesQuery(districtId);
  useEffect(() => {
    if (isVillageSuccess) {
      setVillageOptions(
        villageData?.map((village) => ({
          value: village.id,
          label: village.name,
        }))
      );
    }
  }, [isVillageSuccess, villageData]);

  const [
    createProposal,
    { isLoading, isSuccess, isError, error },
  ] = useCreateProposalMutation();

  const onSaveClicked = async () => {
    try {
      await createProposal({
        subject,
        description,
        element_id: elementId,
        council_member_id: councilMemberId,
        village_id: villageId,
        location_detail: locationDetail,
      }).unwrap();
    } catch (err) {
      Sentry.captureException('Failed to save proposal: ', err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Proposal saved successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setSubject('');
      setDescription('');
      setElementId('');
      setCouncilMemberId('');
      history.push('/proposal/me');
    } else if (isError) {
      toast.error(`Error saving proposal ${error?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccess, isError, error, history]);
  useEffect(() => {
    setCouncilMemberId('');
  }, [factionId, constituencyId]);
  useEffect(() => {
    setConstituencyId('');
  }, [factionId]);

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Tambah Usulan
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Usulan
                  </label>
                  <Input
                    type="text"
                    placeholder="Usulan"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Deskripsi
                  </label>
                  <Input
                    type="text"
                    placeholder="Deskripsi"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Fraksi
                  </label>
                  <Select
                    placeholder="Pilih Fraksi"
                    options={factionOptions}
                    onChange={(e) => setFactionId(e.value)}
                    value={factionOptions?.find(
                      (option) => option.value === factionId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Dapil
                  </label>
                  <Select
                    placeholder="Pilih Dapil"
                    options={constituencyOptions}
                    onChange={(e) => setConstituencyId(e.value)}
                    value={
                      constituencyId
                        ? constituencyOptions?.find(
                            (option) => option.value === constituencyId
                          )
                        : null
                    }
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Anggota Dewan
                  </label>
                  <Select
                    placeholder="Pilih Anggota Dewan"
                    options={councilMemberOptions}
                    onChange={(e) => setCouncilMemberId(e.value)}
                    value={
                      councilMemberId
                        ? councilMemberOptions?.find(
                            (option) => option.value === councilMemberId
                          )
                        : null
                    }
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Element
                  </label>
                  <Select
                    placeholder="Pilih Element"
                    options={elementOptions}
                    onChange={(e) => setElementId(e.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Kecamatan
                  </label>
                  <Select
                    placeholder="Pilih Kecamatan"
                    options={districtOptions}
                    onChange={(e) => setDistrictId(e.value)}
                    value={
                      districtId
                        ? districtOptions?.find(
                            (option) => option.value === districtId
                          )
                        : null
                    }
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Desa
                  </label>
                  <Select
                    placeholder="Pilih Desa"
                    options={villageOptions}
                    onChange={(e) => setVillageId(e.value)}
                    value={
                      villageId
                        ? villageOptions?.find(
                            (option) => option.value === villageId
                          )
                        : null
                    }
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Detail Lokasi
                  </label>
                  <Input
                    type="textarea"
                    placeholder="Detail Lokasi"
                    value={locationDetail}
                    onChange={(e) => setLocationDetail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
