import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import storage from 'redux-persist/lib/storage';

const initialState = {
  fullName: null,
  role: null,
  token: null,
  refreshToken: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action) {
      const { fullName, token, refreshToken } = action.payload;
      const decoded = jwtDecode(token);
      const role = decoded?.sub?.role;
      state.fullName = fullName;
      state.role = role;
      state.token = token;
      state.refreshToken = refreshToken;
      state.isAuthenticated = true;
    },
    // logOut resets the state and storage to the initial state
    logOut: () => {
      storage.removeItem('persist:root');
      return initialState;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRole = (state) => state.auth.role;
