import React, { useEffect, useRef, useState } from 'react';
import Auth1 from '../../components/Layouts/Auth/Auth1';
import footerauth from '../../_texts/auth/footers/footerauth';
import navbarlinks from '../../_texts/auth/navbarlinks';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from './authApiSlice';
import Input from '../../components/Elements/Input';
import Checkbox from '../../components/Elements/Checkbox';
import Button from '../../components/Elements/Button';
import { setCredentials } from './authSlice';
import Alert from '../../components/Elements/Alert';
import * as Sentry from '@sentry/react';

import registerBg from '../../assets/img/register_bg_2.png';

const checkbox = {
  label: 'Remember me',
};
const button = {
  fullWidth: true,
  color: 'dark',
  children: 'Masuk',
};

// need to import the Auth1 layout
export default function Login() {
  const emailRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const location = useLocation();

  const [login, isLoading] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useHistory();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await login({ email, password }).unwrap();
      dispatch(
        setCredentials({
          fullName: data.full_name,
          token: data.access_token,
          refreshToken: data.refresh_token,
          isAuthenticated: true,
        })
      );
      setEmail('');
      setPassword('');
      navigate.push('/dashboard');
    } catch (err) {
      Sentry.captureException('error ', err);
      if (err?.status === 400) {
        setErrMsg(err?.data?.data);
      } else {
        setErrMsg('Login Failed');
      }
      // errRef.current.focus();
    }
  };

  const propsAlert = { children: errMsg, color: 'red' };
  const handleEmailInput = (e) => setEmail(e.target.value);

  const handlePasswordInput = (e) => setPassword(e.target.value);
  return (
    <Auth1 image={registerBg} navbar={navbarlinks} footer={footerauth}>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
              <div className="mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">Masuk</h6>
                </div>
                {errMsg ? <Alert {...propsAlert} /> : null}
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full">
                    <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                      Email
                    </label>
                    <Input
                      type="email"
                      id="email"
                      ref={emailRef}
                      value={email}
                      onChange={handleEmailInput}
                      placeholder="Enter Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="relative w-full">
                    <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                      Password
                    </label>
                    <Input
                      type="password"
                      id="password"
                      value={password}
                      onChange={handlePasswordInput}
                      placeholder="Enter Password"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="mt-2 inline-block">
                    <Checkbox {...checkbox} />
                  </div>

                  <div className="text-center mt-5">
                    <Button {...button} />
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a href="/forgot-password" className="text-blueGray-500 ml-2">
                  <small>Lupa password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <a href="/register" className="text-blueGray-500 mr-2">
                  <small>Buat akun baru</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auth1>
  );
}
