import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { persistor, store } from './app/store';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/tailwind.min.css';
import 'assets/styles/docs.css';
import Landing1 from './views/Landing/Landing1';
import Login from './features/auth/Login';
import Register from './features/auth/Register';
import Dashboard from './features/dashboard/Dashboard';
import { PersistGate } from 'redux-persist/integration/react';
import FractionList from './features/fraction/FractionList';
import FractionAdd from './features/fraction/FractionAdd';
import FractionEdit from './features/fraction/FractionEdit';
import { ToastContainer } from 'react-toastify';
import UserList from './features/user/UserList';
import Profile from './features/user/Profile';
import CommissionEdit from './features/commission/CommissionEdit';
import CommissionCreate from './features/commission/commissionCreate';
import AspirationList from './features/aspiration/AspirationList';
import MyAspirationList from './features/aspiration/MyAspirationList';
import ConstituencyList from './features/constituency/ConstituencyList';
import ConstituencyEdit from './features/constituency/ConstituencyEdit';
import ConstituencyAdd from './features/constituency/ConstituencyAdd';
import UnitList from './features/unit/UnitList';
import UnitAdd from './features/unit/UnitAdd';
import UnitEdit from './features/unit/UnitEdit';
import PositionList from './features/position/PositionList';
import PositionAdd from './features/position/PositionAdd';
import PositionEdit from './features/position/PositionEdit';
import PlanningTypeList from './features/planingType/PlanningTypeList';
import PlanningTypeAdd from './features/planingType/PlanningTypeAdd';
import PlanningTypeEdit from './features/planingType/PlanningTypeEdit';
import Error404 from './views/error/Error404';
import Error500 from './views/error/Error500';
import ElementAdd from './features/element/ElementAdd';
import ElementEdit from './features/element/elementEdit';
import ElementList from './features/element/ElementList';
import RequireAuth from './features/auth/RequireAuth';
import CouncilMemberList from './features/councilMember/CouncilMemberList';
import CouncilMemberAdd from './features/councilMember/CouncilMemberAdd';
import CouncilMemberEdit from './features/councilMember/CouncilMemberEdit';
import LocalGovernmentList from './features/localGovernment/LocalGovernmentList';
import LocalGovernmentAdd from './features/localGovernment/LocalGovernmentAdd';
import LocalGovernmentEdit from './features/localGovernment/LocalGovernmentEdit';
import MyProposalList from './features/proposal/MyProposalList';
import ProposalCreate from './features/proposal/ProposalCreate';
import ProposalEdit from './features/proposal/ProposalEdit';
import ProposalUpload from './features/proposal/ProposalUpload';
import MyAspirationCreate from './features/aspiration/MyAspirationCreate';
import MyAspirationEdit from './features/aspiration/MyAspirationEdit';
import ProposalList from './features/proposal/ProposalList';
import ProposalDetail from './features/proposal/ProposalDetail';
import AuthorityCreate from './features/authority/AuthorityCreate';
import AuthorityList from './features/authority/AuthorityList';
import AuthorityEdit from './features/authority/AuthorityEdit';
import ForgotPassword from './features/auth/ForgotPassword';
import ResetPassword from './features/auth/ResetPassword';
import ChangePassword from './features/user/ChangePassword';
import VerifyEmail from './features/auth/VerifyEmail';
import AspirationDetail from './features/aspiration/AspirationDetail';
import CommissionList from './features/commission/CommissionList';
import Unauthorized from './features/auth/Unauthorized';
import IsAuthenticated from './features/auth/IsAuthenticated';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Landing1} />
            {/* Route Auth */}
            <Route
              path="/login"
              exact
              render={() => (
                <IsAuthenticated>
                  <Login />
                </IsAuthenticated>
              )}
            />
            <Route
              path="/register"
              exact
              render={() => (
                <IsAuthenticated>
                  <Register />
                </IsAuthenticated>
              )}
            />
            <Route path="/unauthorized" exact component={Unauthorized} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/reset-password/:token"
              exact
              component={ResetPassword}
            />
            <Route path="/verify-email/:token" exact component={VerifyEmail} />

            <Route
              path="/profile"
              render={() => (
                <RequireAuth role={['admin', 'aspirator', 'dewan', 'operator']}>
                  <Profile />
                </RequireAuth>
              )}
            />
            <Route
              path="/change-password"
              render={() => (
                <RequireAuth role={['admin', 'aspirator', 'dewan', 'operator']}>
                  <ChangePassword />
                </RequireAuth>
              )}
            />
            <Route path="/dashboard" component={Dashboard} />

            {/* Route Commission */}
            <Route
              path="/commission/:commissionId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <CommissionEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/commission/add"
              render={() => (
                <RequireAuth role={['admin']}>
                  <CommissionCreate />
                </RequireAuth>
              )}
            />
            <Route
              path="/commission"
              render={() => (
                <RequireAuth role={['admin']}>
                  <CommissionList />
                </RequireAuth>
              )}
            />

            {/* Route Fraction */}
            <Route
              path="/fraction/add"
              render={() => (
                <RequireAuth role={['admin']}>
                  <FractionAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/fraction/:fractionId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <FractionEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/fraction"
              render={() => (
                <RequireAuth role={['admin']}>
                  <FractionList />
                </RequireAuth>
              )}
            />

            {/* Route User */}
            <Route
              path="/user"
              render={() => (
                <RequireAuth role={['admin']}>
                  <UserList />
                </RequireAuth>
              )}
            />

            {/* Route Aspiration */}
            <Route
              path="/my-aspiration/:proposalId/create"
              render={() => (
                <RequireAuth role={['dewan']}>
                  <MyAspirationCreate />
                </RequireAuth>
              )}
            />
            <Route
              path="/my-aspiration/create"
              render={() => (
                <RequireAuth role={['dewan']}>
                  <MyAspirationCreate />
                </RequireAuth>
              )}
            />
            <Route
              path="/my-aspiration/:aspirationId/edit"
              render={() => (
                <RequireAuth role={['dewan']}>
                  <MyAspirationEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/my-aspiration"
              render={() => (
                <RequireAuth role={['admin', 'dewan']}>
                  <MyAspirationList />
                </RequireAuth>
              )}
            />
            <Route
              path="/aspiration/:aspirationId/detail"
              render={() => (
                <RequireAuth role={['admin', 'operator']}>
                  <AspirationDetail />
                </RequireAuth>
              )}
            />
            <Route
              path="/aspirations"
              render={() => (
                <RequireAuth role={['admin', 'operator']}>
                  <AspirationList />
                </RequireAuth>
              )}
            />

            {/* Route Constituency */}
            <Route
              path="/constituency/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <ConstituencyAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/constituency/:constituencyId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <ConstituencyEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/constituency"
              render={() => (
                <RequireAuth role={['admin']}>
                  <ConstituencyList />
                </RequireAuth>
              )}
            />

            {/* Unit Route */}
            <Route
              path="/unit/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <UnitAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/unit/:unitId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <UnitEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/unit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <UnitList />
                </RequireAuth>
              )}
            />

            {/* Position Route */}
            <Route
              path="/position/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <PositionAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/position/:positionId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <PositionEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/position"
              render={() => (
                <RequireAuth role={['admin']}>
                  <PositionList />
                </RequireAuth>
              )}
            />

            {/* Planning Type Route */}
            <Route
              path="/planning-type/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <PlanningTypeAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/planning-type/:planningTypeId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <PlanningTypeEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/planning-type"
              render={() => (
                <RequireAuth role={['admin']}>
                  <PlanningTypeList />
                </RequireAuth>
              )}
            />

            {/* Element Route */}
            <Route
              path="/element/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <ElementAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/element/:elementId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <ElementEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/element"
              render={() => (
                <RequireAuth role={['admin']}>
                  <ElementList />
                </RequireAuth>
              )}
            />

            {/* Council Member Route */}
            <Route
              path="/council-member/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <CouncilMemberAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/council-member/:councilMemberId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <CouncilMemberEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/council-member"
              render={() => (
                <RequireAuth role={['admin']}>
                  <CouncilMemberList />
                </RequireAuth>
              )}
            />

            {/* Local Government Organization Route */}
            <Route
              path="/local-government/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <LocalGovernmentAdd />
                </RequireAuth>
              )}
            />
            <Route
              path="/local-government/:localGovernmentId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <LocalGovernmentEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/local-government"
              render={() => (
                <RequireAuth role={['admin']}>
                  <LocalGovernmentList />
                </RequireAuth>
              )}
            />

            {/* Proposal Route */}
            <Route
              path="/proposal/create"
              render={() => (
                <RequireAuth role={['aspirator']}>
                  <ProposalCreate />
                </RequireAuth>
              )}
            />
            <Route
              path="/proposal/:proposalId/edit"
              render={() => (
                <RequireAuth role={['aspirator']}>
                  <ProposalEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/proposal/:proposalId/upload"
              render={() => (
                <RequireAuth role={['aspirator']}>
                  <ProposalUpload />
                </RequireAuth>
              )}
            />
            <Route
              path="/proposal/me"
              render={() => (
                <RequireAuth role={['aspirator']}>
                  <MyProposalList />
                </RequireAuth>
              )}
            />
            <Route
              path="/proposal/:proposalId/detail"
              render={() => (
                <RequireAuth role={['dewan']}>
                  <ProposalDetail />
                </RequireAuth>
              )}
            />
            <Route
              path="/proposal"
              render={() => (
                <RequireAuth role={['dewan']}>
                  <ProposalList />
                </RequireAuth>
              )}
            />

            {/* Authority Route */}
            <Route
              path="/authority/create"
              render={() => (
                <RequireAuth role={['admin']}>
                  <AuthorityCreate />
                </RequireAuth>
              )}
            />
            <Route
              path="/authority/:authorityId/edit"
              render={() => (
                <RequireAuth role={['admin']}>
                  <AuthorityEdit />
                </RequireAuth>
              )}
            />
            <Route
              path="/authority"
              render={() => (
                <RequireAuth role={['admin']}>
                  <AuthorityList />
                </RequireAuth>
              )}
            />

            {/* error */}
            <Route path="/error-404" exact component={Error404} />
            <Route path="/error-500" exact component={Error500} />
            <Redirect from="*" to="/error-404" />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
