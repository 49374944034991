import { apiSlice } from '../../app/api/apiSlice';

export const localGovernmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocalGovernments: builder.query({
      query: () => ({
        url: '/local-government-organizations',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({
          type: 'LocalGovernment',
          id,
        })),
        { type: 'LocalGovernment', id: 'LIST' },
      ],
    }),
    getLocalGovernment: builder.query({
      query: (id) => ({
        url: `/local-government-organization/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'LocalGovernment',
          id: arg,
        },
      ],
    }),
    createLocalGovernment: builder.mutation({
      query: (body) => ({
        url: '/local-government-organization',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'LocalGovernment', id: 'LIST' },
      ],
    }),
    updateLocalGovernment: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/local-government-organization/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'LocalGovernment', id: 'LIST' },
      ],
    }),
    deleteLocalGovernment: builder.mutation({
      query: (id) => ({
        url: `/local-government-organization/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'LocalGovernment', id: arg.id },
      ],
    }),

    // get local government by authority id
    getLocalGovernmentByAuthorityId: builder.query({
      query: (id) => ({
        url: `/local-government-organizations/authority/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result ? [{ type: 'LocalGovernment', id: result.id }] : [],
    }),
  }),
});

export const {
  useGetLocalGovernmentsQuery,
  useGetLocalGovernmentQuery,
  useCreateLocalGovernmentMutation,
  useUpdateLocalGovernmentMutation,
  useDeleteLocalGovernmentMutation,
  useGetLocalGovernmentByAuthorityIdQuery,
} = localGovernmentApiSlice;
