import { apiSlice } from '../../app/api/apiSlice';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';

const missionAdapter = createEntityAdapter();
const initialState = missionAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const missionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMissionsLocalGovernment: builder.query({
      query: (localGovernmentId) => ({
        url: `/missions/local-governments/${localGovernmentId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Mission',
                id,
              })),
              { type: 'Mission', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const { useGetMissionsLocalGovernmentQuery } = missionApiSlice;
export const selectMissionsLocalGovernment = missionApiSlice.endpoints.getMissionsLocalGovernment.select();

const selectMissionData = createSelector(
  selectMissionsLocalGovernment,
  (missionState) => missionState.data
);
