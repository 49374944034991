import dropdownnavbar from '_texts/misc/dropdowns/dropdownnavbar.js';

const textProps = {
  color: 'dark',
  type: 'fixed',
  logoText: 'e-Asmara',
  logoLink: {
    to: '/',
  },
  socials: [
    {
      icon: 'facebook',
      text: 'Share on Facebook',
      link: 'https://www.facebook.com/sekretariatdprdpemalang/',
    },
    {
      icon: 'twitter',
      text: 'Tweet on Twitter',
      link: 'https://twitter.com/DPRD_Pemalang',
    },
  ],
  leftLinks: [
    // {
    //   href: '#pablo',
    //   children: 'Elements',
    // },
    // {
    //   href: '#pablo',
    //   children: 'Sections',
    // },
    // {
    //   dropdown: true,
    //   ...dropdownnavbar,
    // },
  ],
};
export default textProps;
