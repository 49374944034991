/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';

// components

export default function CardDashboardProfile({
  image,
  name,
  commission,
  faction,
  position,
  constituency,
}) {
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg mt-16">
      <div className="px-6">
        <div className="flex flex-wrap justify-center">
          <div className="w-full flex justify-center">
            <div className="relative">
              <img
                alt="..."
                src={image}
                className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16  max-w-100-px"
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-16">
          <h3 className="text-xl font-bold leading-normal mb-2">{name}</h3>
          {commission && (
            <h4 className="text-sm font-bold leading-normal mb-2">
              {`Komisi ${commission}`}
            </h4>
          )}
          {faction && (
            <h4 className="text-sm font-bold leading-normal mb-2">
              {`Fraksi ${faction}`}
            </h4>
          )}
          {position && (
            <h4 className="text-sm font-bold leading-normal mb-2">
              {`Jabatan ${position}`}
            </h4>
          )}
          {constituency && (
            <h4 className="text-sm font-bold leading-normal mb-2">
              {`Dapil ${constituency}`}
            </h4>
          )}
        </div>
      </div>
    </div>
  );
}

CardDashboardProfile.defaultProps = {
  image: require('assets/img/team-2-800x800.jpg').default,
};

CardDashboardProfile.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  commission: PropTypes.string,
  faction: PropTypes.string,
  position: PropTypes.string,
  constituency: PropTypes.string,
};
