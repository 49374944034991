import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import React, { useEffect, useState } from 'react';
import { useGetAuthoritiesQuery } from '../authority/authorityApiSlice';
import Select from 'react-select';
import { useGetPlanningTypesQuery } from '../planingType/planningTypeApiSlice';
import { useGetUnitsQuery } from '../unit/unitApiSlice';
import { useGetProgramsQuery } from '../program/programApiSlice';
import { useGetMissionsLocalGovernmentQuery } from '../mission/missionApiSlice';
import {
  useGetRegionsByCouncilMemberIdQuery,
  useGetVillagesQuery,
} from '../region/regionApiSlice';
import { toast } from 'react-toastify';
import {
  useCreateAspirationProposalMutation,
  useCreateMyAspirationMutation,
} from './aspirationApiSlice';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useGetProposalByCouncilMemberDetailQuery } from '../proposal/proposalApiSlice';
import { useGetLocalGovernmentByAuthorityIdQuery } from '../localGovernment/localGovernmentApiSlice';
import * as Sentry from '@sentry/react';

export default function MyAspirationCreate() {
  const { proposalId } = useParams();
  const history = useHistory();
  const [aspirationProposal, setAspirationProposal] = useState('');
  const [authorityId, setAuthorityId] = useState('');
  const [budget, setBudget] = useState(0);
  const [
    localGovernmentOrganizationId,
    setLocalGovernmentOrganizationId,
  ] = useState('');
  const [locationDetail, setLocationDetail] = useState('');
  const [missionId, setMissionId] = useState('');
  const [planningTypeId, setPlanningTypeId] = useState('');
  const [programId, setProgramId] = useState('');
  const [unitId, setUnitId] = useState('');
  const [villageId, setVillageId] = useState(0);
  const [volume, setVolume] = useState(0);
  const [yearOfPlanning, setYearOfPlanning] = useState(new Date());
  const [missionOptions, setMissionOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [districtId, setDistrictId] = useState(0);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [element, setElement] = useState('');
  const [commission, setCommission] = useState('');
  const [faction, setFaction] = useState('');
  const [councilMember, setCouncilMember] = useState('');
  const [status, setStatus] = useState('');
  const [proposalDocument, setProposalDocument] = useState('');
  const [user, setUser] = useState([]);
  const [localGovernmentOptions, setLocalGovernmentOptions] = useState([]);
  const [proposalDistrict, setProposalDistrict] = useState('');
  const [proposalVillage, setProposalVillage] = useState('');
  const [proposalDetailLocation, setProposalDetailLocation] = useState('');

  const {
    data: proposalData,
    isSuccess: isSuccessProposal,
  } = useGetProposalByCouncilMemberDetailQuery(proposalId);
  useEffect(() => {
    if (isSuccessProposal) {
      setSubject(proposalData.subject);
      setDescription(proposalData.description);
      setElement(proposalData.element);
      setCommission(proposalData.commission);
      setFaction(proposalData.faction);
      setCouncilMember(proposalData.council_member_name);
      setStatus(proposalData.status);
      setProposalDocument(proposalData.proposal_document);
      setUser(proposalData.user);
    }
  }, [isSuccessProposal, proposalData]);

  const authorityOptions = useGetAuthoritiesQuery().data?.map((authority) => ({
    value: authority.id,
    label: authority.name,
  }));
  // const localGovernmentOrganizationOptions = useGetLocalGovernmentsQuery().data?.map(
  //   (localGovernmentOrganization) => ({
  //     value: localGovernmentOrganization.id,
  //     label: localGovernmentOrganization.name,
  //   })
  // );
  const {
    data: localGovernmentData,
    isSuccess: isSuccessLocalGovernment,
  } = useGetLocalGovernmentByAuthorityIdQuery(authorityId);
  useEffect(() => {
    if (isSuccessLocalGovernment) {
      setLocalGovernmentOptions(
        localGovernmentData.map((localGovernment) => ({
          value: localGovernment.id,
          label: localGovernment.name,
        }))
      );
    }
  }, [isSuccessLocalGovernment, localGovernmentData]);

  const {
    data: missions,
    isSuccess: isSuccessMission,
  } = useGetMissionsLocalGovernmentQuery(localGovernmentOrganizationId);
  useEffect(() => {
    if (isSuccessMission) {
      setMissionOptions(
        missions.map((mission) => ({
          value: mission.id,
          label: mission.name,
        }))
      );
    }
  }, [isSuccessMission, missions]);

  function onChangeLocalGovernment(selectedOption) {
    setLocalGovernmentOrganizationId(selectedOption.value);
  }

  const { data: programs, isSuccess: isSuccessProgram } = useGetProgramsQuery({
    localGovernmentId: localGovernmentOrganizationId,
    missionId,
  });
  useEffect(() => {
    if (isSuccessProgram) {
      if (programs) {
        setProgramOptions(
          programs.map((program) => ({
            value: program.id,
            label: program.name,
          }))
        );
      }
    }
  }, [isSuccessProgram, programs]);

  const planningTypeOptions = useGetPlanningTypesQuery().data?.map(
    (planningType) => ({
      value: planningType.id,
      label: planningType.name,
    })
  );

  const unitOptions = useGetUnitsQuery().data?.map((unit) => ({
    value: unit.id,
    label: unit.name,
  }));
  const districtOptions = useGetRegionsByCouncilMemberIdQuery().data?.map(
    (district) => ({
      value: district.id,
      label: district.name,
    })
  );
  const { data: villages, isSuccess: isSuccessVillage } = useGetVillagesQuery(
    districtId
  );
  useEffect(() => {
    if (isSuccessVillage) {
      setVillageOptions(
        villages?.map((village) => ({
          value: village.id,
          label: village.name,
        }))
      );
    }
  }, [isSuccessVillage, villages]);

  const [
    createAspiration,
    { isSuccess: isSuccessCreate, isError: isErrorCreate, error: errorCreate },
  ] = useCreateMyAspirationMutation();
  const [
    createAspirationProposal,
    {
      isSuccess: isSuccessCreateProposal,
      isError: isErrorCreateProposal,
      error: errorCreateProposal,
    },
  ] = useCreateAspirationProposalMutation();
  const onSaveClicked = async () => {
    try {
      if (proposalId) {
        await createAspirationProposal({
          proposalId,
          aspiration_proposal: aspirationProposal,
          authority_id: authorityId,
          budget: parseInt(budget),
          local_government_organization_id: localGovernmentOrganizationId,
          location_detail: locationDetail,
          mission_id: missionId,
          planning_type_id: planningTypeId,
          program_id: programId,
          unit_id: unitId,
          village_id: villageId,
          volume: parseInt(volume),
          years_of_planning: yearOfPlanning.year(),
        });
      } else {
        await createAspiration({
          aspiration_proposal: aspirationProposal,
          authority_id: authorityId,
          budget: parseInt(budget),
          local_government_organization_id: localGovernmentOrganizationId,
          location_detail: locationDetail,
          mission_id: missionId,
          planning_type_id: planningTypeId,
          program_id: programId,
          unit_id: unitId,
          village_id: villageId,
          volume: parseInt(volume),
          years_of_planning: yearOfPlanning.year(),
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (isSuccessCreate) {
      toast.success('Aspiration Proposal Created', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setAspirationProposal('');
      setAuthorityId('');
      setBudget(0);
      setLocalGovernmentOrganizationId('');
      setLocationDetail('');
      setMissionId('');
      setPlanningTypeId('');
      setProgramId('');
      setUnitId('');
      setVillageId(0);
      setVolume(0);
      setYearOfPlanning(0);
      history.push('/my-aspiration');
    } else if (isErrorCreate) {
      toast.error(errorCreate, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isSuccessCreateProposal) {
      toast.success('Aspiration Proposal Created', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setAspirationProposal('');
      setAuthorityId('');
      setBudget(0);
      setLocalGovernmentOrganizationId('');
      setLocationDetail('');
      setMissionId('');
      setPlanningTypeId('');
      setProgramId('');
      setUnitId('');
      setVillageId(0);
      setVolume(0);
      setYearOfPlanning(0);
      history.push('/my-aspiration');
    } else if (isErrorCreateProposal) {
      toast.error(errorCreateProposal, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [
    isSuccessCreate,
    isErrorCreate,
    errorCreate,
    isSuccessCreateProposal,
    isErrorCreateProposal,
    errorCreateProposal,
  ]);
  let cardSize;
  if (proposalId) {
    cardSize = 'w-full lg:w-6/12 px-4';
  } else {
    cardSize = 'w-full lg:w-12/12 px-4';
  }

  function openNewTab(proposalDocument) {
    window.open(proposalDocument, '_blank');
  }

  useEffect(() => {
    setLocalGovernmentOrganizationId('');
  }, [authorityId]);
  useEffect(() => {
    setMissionId('');
  }, [localGovernmentOrganizationId]);
  useEffect(() => {
    setProgramId('');
  }, [missionId]);

  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="flex flex-wrap">
        <div className={cardSize}>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full lg:w-12/12 mb-6 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <form>
                <div className="container mx-auto px-4">
                  <h3 className="text-3xl font-semibold mt-4 mb-6">
                    Tambah Aspirasi
                  </h3>
                  <div className="flex flex-wrap -mx-4 ">
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Tahun Perencanaan
                      </label>
                      <Datetime
                        className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
                        onChange={(date) => setYearOfPlanning(date)}
                        initialValue={yearOfPlanning}
                        initialViewDate={yearOfPlanning}
                        dateFormat="YYYY"
                        timeFormat={false}
                        input={false}
                        value={yearOfPlanning}
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Aspirasi Proposal
                      </label>
                      <Input
                        type="text"
                        placeholder="Pembangunan Jalan Pelutan"
                        value={aspirationProposal}
                        onChange={(e) => setAspirationProposal(e.target.value)}
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Kewenangan
                      </label>
                      <Select
                        placeholder="Pilih Kewenangan"
                        options={authorityOptions}
                        onChange={(e) => setAuthorityId(e.value)}
                        value={
                          authorityId
                            ? authorityOptions.find(
                                (obj) => obj.value === authorityId
                              )
                            : null
                        }
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        OPD
                      </label>
                      <Select
                        placeholder="Pilih OPD"
                        options={localGovernmentOptions}
                        onChange={(e) => onChangeLocalGovernment(e)}
                        value={
                          localGovernmentOrganizationId
                            ? localGovernmentOptions.find(
                                (obj) =>
                                  obj.value === localGovernmentOrganizationId
                              )
                            : null
                        }
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Misi
                      </label>
                      <Select
                        placeholder="Pilih Misi"
                        options={missionOptions}
                        onChange={(e) => setMissionId(e.value)}
                        value={
                          missionId
                            ? missionOptions.find(
                                (obj) => obj.value === missionId
                              )
                            : null
                        }
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Program
                      </label>
                      <Select
                        placeholder="Pilih Program"
                        options={programOptions}
                        onChange={(e) => setProgramId(e.value)}
                        value={
                          programId
                            ? programOptions.find(
                                (obj) => obj.value === programId
                              )
                            : null
                        }
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Jenis Perencanaan
                      </label>
                      <Select
                        placeholder="Pilih Jenis Perencanaan"
                        options={planningTypeOptions}
                        onChange={(e) => setPlanningTypeId(e.value)}
                        value={
                          planningTypeId
                            ? planningTypeOptions.find(
                                (obj) => obj.value === planningTypeId
                              )
                            : null
                        }
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Volume
                      </label>
                      <Input
                        type="number"
                        placeholder="Volume"
                        value={volume}
                        onChange={(e) => setVolume(e.target.value)}
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Satuan
                      </label>
                      <Select
                        placeholder="Pilih Satuan"
                        options={unitOptions}
                        onChange={(e) => setUnitId(e.value)}
                        value={
                          unitId
                            ? unitOptions.find((obj) => obj.value === unitId)
                            : null
                        }
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Budget
                      </label>
                      <Input
                        type="number"
                        placeholder="Budget"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Kecamatan
                      </label>
                      <Select
                        placeholder="Pilih Kecaamatan"
                        options={districtOptions}
                        onChange={(e) => setDistrictId(e.value)}
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Desa
                      </label>
                      <Select
                        placeholder="Pilih Desa"
                        options={villageOptions}
                        onChange={(e) => setVillageId(e.value)}
                      />
                    </div>
                    <div className="px-4 pb-2 relative w-full lg:w-12/12">
                      <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                        Detail Lokasi
                      </label>
                      <Input
                        type="text-area"
                        rows="3"
                        placeholder="Detail Lokasi"
                        value={locationDetail}
                        onChange={(e) => setLocationDetail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between mt-12 mb-8">
                    <Button
                      type="button"
                      color="light"
                      children="Simpan"
                      onClick={onSaveClicked}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {proposalId && (
          <div className={cardSize}>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full lg:w-12/12 mb-6 shadow-lg rounded-lg">
              <div className="px-4 py-5 flex-auto">
                <form>
                  <div className="container mx-auto px-4">
                    <div className="flex flex-row flex-wrap justify-between text-center">
                      <h3 className="text-3xl font-semibold mt-4 mb-6">
                        Detail Proposal
                      </h3>
                    </div>
                    <div className="flex flex-wrap -mx-4">
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Subject
                        </label>
                        <Input
                          type="text"
                          placeholder="Subject"
                          value={subject}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Deskripsi
                        </label>
                        <Input
                          type="text"
                          placeholder="Deskripsi"
                          value={description}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Element
                        </label>
                        <Input
                          type="text"
                          placeholder="Element"
                          value={element}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Komisi
                        </label>
                        <Input
                          type="text"
                          placeholder="Komisi"
                          value={commission}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Fraksi
                        </label>
                        <Input
                          type="text"
                          placeholder="Fraksi"
                          value={faction}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Nama Anggota Dewan
                        </label>
                        <Input
                          type="text"
                          placeholder="Nama Anggota Dewan"
                          value={councilMember}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Kecamatan
                        </label>
                        <Input
                          type="text"
                          placeholder="Kecamatan"
                          value={proposalDistrict}
                          disabled
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Desa
                        </label>
                        <Input
                          type="text"
                          placeholder="Desa"
                          value={proposalVillage}
                          disabled
                        />
                        />
                      </div>
                      <div className="px-4 pb-2 relative w-full lg:w-12/12">
                        <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                          Detail Lokasi
                        </label>
                        <Input
                          type="textarea"
                          placeholder="Detail Lokasi"
                          value={proposalDetailLocation}
                          disabled
                        />
                      </div>
                      {proposalDocument && (
                        <div className="px-4 pb-2 relative w-full lg:w-12/12">
                          <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                            Proposal
                          </label>
                          <Button
                            color="white"
                            size="lg"
                            onClick={() => openNewTab(proposalDocument)}
                          >
                            <i className="fas fa-file-pdf" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Admin1>
  );
}
