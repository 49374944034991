// props
const sidebar = {
  brand: {
    text: process.env.REACT_APP_NAME,
    image: require('assets/img/brand/tim_60x61.png').default,
    link: {
      href: '#/',
    },
  },
  activeColor: 'lightBlue',
  items: [
    {
      divider: true,
    },
    {
      title: 'Section 1',
    },
    {
      icon: 'fas fa-tv',
      text: 'Dashboard',
      active: true,
      link: {
        href: '#/dashboard',
      },
    },
    {
      icon: 'fas fa-tools',
      text: 'Aspirasi Masyarakat',
      link: {
        href: '#/aspirasi',
      },
    },
    {
      icon: 'fas fa-table',
      text: 'Pokir',
      link: {
        href: '#pablo',
      },
    },
    {
      divider: true,
    },
    {
      title: 'Section 2',
    },
    {
      icon: 'fas fa-map-marked',
      text: 'User Management',
      link: {
        href: '#pablo',
      },
    },
    {
      divider: true,
    },
  ],
};
const navbarsearchusersettings2 = {
  // brand: { href: '#pablo', children: 'Settings Page' },
  // input: { placeholder: 'Search here', border: 'borderless', type: 'text' },
  dropdown: {
    image: '/notus-pro-react/static/media/team-1-800x800.fa5a7ac2.jpg',
    items: [
      { href: '#pablo', children: 'Action' },
      { href: '#pablo', children: 'Another action' },
      { href: '#pablo', children: 'Something else here' },
      { divider: true },
      { href: '#pablo', children: 'Seprated link' },
    ],
  },
};
const headerstatcards = {
  color: 'lightBlue',
  // cards: [
  //   {
  //     statSubtitle: 'Traffic',
  //     statTitle: '350,897',
  //     statArrow: 'up',
  //     statPercent: '3.48',
  //     statPercentColor: 'text-emerald-500',
  //     statDescription: 'Since last month',
  //     statIconName: 'far fa-chart-bar',
  //     statIconColor: 'bg-red-500',
  //   },
  //   {
  //     statSubtitle: 'NEW USERS',
  //     statTitle: '2,356',
  //     statArrow: 'down',
  //     statPercent: '3.48',
  //     statPercentColor: 'text-red-500',
  //     statDescription: 'Since last week',
  //     statIconName: 'fas fa-chart-pie',
  //     statIconColor: 'bg-orange-500',
  //   },
  //   {
  //     statSubtitle: 'SALES',
  //     statTitle: '924',
  //     statArrow: 'down',
  //     statPercent: '1.10',
  //     statPercentColor: 'text-orange-500',
  //     statDescription: 'Since yesterday',
  //     statIconName: 'fas fa-users',
  //     statIconColor: 'bg-pink-500',
  //   },
  //   {
  //     statSubtitle: 'PERFORMANCE',
  //     statTitle: '49,65%',
  //     statArrow: 'up',
  //     statPercent: '12',
  //     statPercentColor: 'text-emerald-500',
  //     statDescription: 'Since last month',
  //     statIconName: 'fas fa-percent',
  //     statIconColor: 'bg-lightBlue-500',
  //   },
  // ],
};
const footeradmin = {
  copyright: {
    href: '/',
    target: '_blank',
    children: `Copyright © ${new Date().getFullYear()} DPRD KABUPATEN PEMALANG`,
  },
};

export { sidebar, navbarsearchusersettings2, headerstatcards, footeradmin };
