import { apiSlice } from '../../app/api/apiSlice';

export const elementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getElements: builder.query({
      query: () => ({
        url: '/elements',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({
          type: 'Element',
          id,
        })),
        { type: 'Element', id: 'LIST' },
      ],
    }),
    getElement: builder.query({
      query: (id) => `/element/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Element',
          id: arg,
        },
      ],
    }),
    createElement: builder.mutation({
      query: (body) => ({
        url: '/element',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Element', id: 'LIST' },
      ],
    }),
    updateElement: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/element/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Element', id: 'LIST' },
      ],
    }),
    deleteElement: builder.mutation({
      query: (id) => ({
        url: `/element/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Element', id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetElementsQuery,
  useGetElementQuery,
  useCreateElementMutation,
  useUpdateElementMutation,
  useDeleteElementMutation,
} = elementApiSlice;
