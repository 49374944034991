import Admin from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import { useHistory } from 'react-router-dom';
import {
  useDeleteAuthorityMutation,
  useGetAuthoritiesQuery,
} from './authorityApiSlice';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import * as Sentry from '@sentry/react';

export default function AuthorityList() {
  const history = useHistory();
  const { data: authorities, isLoading } = useGetAuthoritiesQuery();

  function onClickEdit(id) {
    history.push(`/authority/${id}/edit`);
  }

  const [
    deleteAuthority,
    { isSuccess, isError, error },
  ] = useDeleteAuthorityMutation();
  const onClickDelete = async (id) => {
    try {
      await deleteAuthority(id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success('Successfully deleted authority', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isError) {
      toast.error(`Error deleting authority ${error?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccess, isError, error]);
  const columns = [
    {
      name: 'Nama',
      selector: (row) => row.name,
      sortable: true,
      cellExport: (row) => row.name,
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <div className="flex items-center">
          <Button
            size="sm"
            type="button"
            color="orange"
            children="Ubah"
            onClick={() => onClickEdit(row.id)}
          />
          <Button
            size="sm"
            type="button"
            color="red"
            children="Hapus"
            onClick={() => onClickDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white text-blueGray-700">
        <div className="px-6 py-4 border-0">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h3 className="font-bold text-lg white">Kewenangan</h3>
            <Button
              type="button"
              color="lightBlue"
              children="Tambah Kewenangan"
              size="sm"
              onClick={() => history.push('/authority/create')}
            />
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <DataTableExtensions
            columns={columns}
            data={authorities || []}
            filterPlaceholder="Cari Kewenangan"
            exportHeaders={true}
            print={false}
            fileName="Kewenangan"
          >
            <DataTable
              columns={columns}
              data={authorities || []}
              progressPending={isLoading}
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </div>
    </Admin>
  );
}
