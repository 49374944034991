import profileImg from '../../../assets/img/team-1-800x800.jpg';

const textProps = {
  image: profileImg,
  items: [
    {
      href: '/profile',
      children: 'Profile',
    },
    {
      divider: true,
    },
  ],
};
export default textProps;
