/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function FooterAdmin({ copyright, links }) {
  return (
    <footer className="block py-4">
      <div className="container mx-auto px-4">
        <hr className="mb-4 border-b-1 border-blueGray-200" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-12/12 px-4">
            <div className="text-center mb-4 md:text-left md:mb-0">
              {copyright && copyright.to && (
                <Link
                  {...copyright}
                  className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left"
                />
              )}
              {copyright && copyright.to === undefined && (
                <a
                  {...copyright}
                  className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

FooterAdmin.defaultProps = {
  copyright: {},
  links: [],
};

FooterAdmin.propTypes = {
  // properties to pass to the link object
  // that sits on the left of the footer
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  copyright: PropTypes.object,
  // array of properties to pass to the link object
  // that are on the right side of the footer
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  links: PropTypes.arrayOf(PropTypes.object),
};
