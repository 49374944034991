import { useHistory } from 'react-router-dom';
import {
  useDeleteProposalMutation,
  useGetProposalsByCouncilMemberQuery,
  useStatisticByCouncilMemberQuery,
} from './proposalApiSlice';
import Admin from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import * as Sentry from '@sentry/react';

export default function ProposalList() {
  const history = useHistory();
  const { data: proposals, isLoading } = useGetProposalsByCouncilMemberQuery();
  const [stats, setStats] = useState([]);

  function onClickEdit(id) {
    history.push(`/proposal/${id}/edit`);
  }

  const [
    deleteProposal,
    { isSuccess: isSuccessDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteProposalMutation();

  const onClickDelete = async (id) => {
    try {
      await deleteProposal(id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success('Successfully deleted proposal', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isErrorDelete) {
      toast.error(`Error deleting proposal ${errorDelete?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccessDelete, isErrorDelete, errorDelete]);

  function onClickDetail(id) {
    history.push(`/proposal/${id}/detail`);
  }

  const columns = [
    {
      name: 'Nama',
      selector: (row) => row.user,
      sortable: true,
      cellExport: (row) => row.user,
    },
    {
      name: 'Element',
      selector: (row) => row.element,
      sortable: true,
      cellExport: (row) => row.element,
    },
    {
      name: 'Usulan',
      selector: (row) => row.subject,
      sortable: true,
      cellExport: (row) => row.subject,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cellExport: (row) => row.status,
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <div className="flex flex-wrap">
          <Button
            size="sm"
            type="button"
            color="lightBlue"
            children="Detail"
            onClick={() => onClickDetail(row.id)}
          />
        </div>
      ),
    },
  ];
  const { data: proposalStats } = useStatisticByCouncilMemberQuery();
  useEffect(() => {
    if (proposalStats) {
      setStats(proposalStats);
    }
  }, [proposalStats]);
  const headerstatcards = {
    color: 'lightBlue',
    cards: [
      {
        statSubtitle: 'Proposal Disetujui',
        statTitle: `${stats.countProposalApproved}`,
        // statArrow: 'up',
        // statPercent: '3.48',
        statPercentColor: 'text-emerald-500',
        statDescription: `dari total ${stats.countAllProposal} Proposal`,
        statIconName: 'fas fa-file-contract',
        statIconColor: 'bg-lightBlue-500',
      },
      {
        statSubtitle: 'Proposal Pending',
        statTitle: `${stats.countProposalPending}`,
        // statArrow: 'down',
        // statPercent: '3.48',
        statPercentColor: 'text-red-500',
        // statDescription: 'Since last week',
        statIconName: 'fas fa-file-contract',
        statIconColor: 'bg-orange-500',
      },
      {
        statSubtitle: 'Proposal Reviewed',
        statTitle: `${stats.countProposalReviewed}`,
        // statArrow: 'down',
        // statPercent: '1.10',
        statPercentColor: 'text-orange-500',
        // statDescription: 'Since yesterday',
        statIconName: 'fas fa-file-contract',
        statIconColor: 'bg-pink-500',
      },
      {
        statSubtitle: 'Proposal Ditolak',
        statTitle: `${stats.countProposalRejected}`,
        // statArrow: 'up',
        // statPercent: '12',
        statPercentColor: 'text-emerald-500',
        // statDescription: 'Since last month',
        statIconName: 'fas fa-file-contract',
        statIconColor: 'bg-red-500',
      },
    ],
  };
  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white text-blueGray-700">
        <div className="px-6 py-4 border-0">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h3 className="font-bold text-lg white">Usulan</h3>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <DataTableExtensions
            columns={columns}
            data={proposals || []}
            filterPlaceholder="Cari Proposal"
            exportHeaders={true}
            print={false}
            fileName="Proposal"
          >
            <DataTable
              columns={columns}
              data={proposals || []}
              progressPending={isLoading}
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </div>
    </Admin>
  );
}
