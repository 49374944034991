import { useHistory } from 'react-router-dom';
import {
  useBannedUserMutation,
  useGetUsersQuery,
  useRequestPasswordResetMutation,
  useStatisticsQuery,
  useUnbannedUserMutation,
} from './userApiSlice';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import Admin from '../../components/Layouts/Admin/Admin1';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import * as Sentry from '@sentry/react';

export default function UserList() {
  const history = useHistory();
  const param = { page: 1 };
  const [stats, setStats] = useState([]);
  const { data: users, isLoading } = useGetUsersQuery(param);
  const [
    bannedUser,
    { isSuccess: isBannedSuccess, isError: isBannedError, error: errorBanned },
  ] = useBannedUserMutation();
  const [
    unbannedUser,
    {
      isSuccess: isUnbannedSuccess,
      isError: isUnbannedError,
      error: errorUnbanned,
    },
  ] = useUnbannedUserMutation();
  const [
    requestPasswordReset,
    { isSuccess: isRequestPasswordSuccess, data: successRequestPassword },
  ] = useRequestPasswordResetMutation();

  const onClickBannedUser = async (userId) => {
    try {
      await bannedUser(userId);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isBannedSuccess) {
      toast.success('User banned', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    if (isBannedError) {
      toast.error('Error banned user', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isBannedSuccess, isBannedError]);

  const onClickUnbannedUser = async (userId) => {
    try {
      await unbannedUser(userId);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isUnbannedSuccess) {
      toast.success('User unbanned', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    if (isUnbannedError) {
      toast.error('Error unbanned user', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isUnbannedSuccess, isUnbannedError]);

  const onClickRequestResetPasswordUser = async (userId) => {
    try {
      await requestPasswordReset(userId);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isRequestPasswordSuccess) {
      toast.success(
        `Request Reset Password Success, ${successRequestPassword.data}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }, [isRequestPasswordSuccess, successRequestPassword]);

  const columns = [
    {
      name: 'Nama',
      selector: (row) => row.full_name,
      sortable: true,
      cellExport: (row) => row.full_name,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      cellExport: (row) => row.email,
    },
    {
      name: 'Role',
      selector: (row) => row.role_name,
      sortable: true,
      cellExport: (row) => row.role_name,
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <div className="flex flex-wrap">
          <Button
            size="sm"
            type="button"
            color="lightBlue"
            children="Request Reset Password"
            onClick={() => onClickRequestResetPasswordUser(row.id)}
          />
          {row.status === 'active' ? (
            <Button
              size="sm"
              type="button"
              color="red"
              children="Banned"
              onClick={() => onClickBannedUser(row.id)}
            />
          ) : (
            <Button
              size="sm"
              type="button"
              color="orange"
              children="Unbanned"
              onClick={() => onClickUnbannedUser(row.id)}
            />
          )}
        </div>
      ),
    },
  ];
  const { data: userStats } = useStatisticsQuery();
  useEffect(() => {
    if (userStats) {
      setStats(userStats);
    }
  }, [userStats]);
  const headerstatcards = {
    color: 'lightBlue',
    cards: [
      {
        statSubtitle: 'Total Users',
        statTitle: `${stats.countAllUser}`,
        // statArrow: 'up',
        // statPercent: '3.48',
        statPercentColor: 'text-emerald-500',
        // statDescription: 'Since last month',
        statIconName: 'fas fa-users',
        statIconColor: 'bg-lightBlue-500',
      },
      {
        statSubtitle: 'User Anggota Dewan',
        statTitle: `${stats.countUserCouncilMemberRole}`,
        // statArrow: 'down',
        // statPercent: '3.48',
        statPercentColor: 'text-red-500',
        // statDescription: 'Since last week',
        statIconName: 'fas fa-users',
        statIconColor: 'bg-orange-500',
      },
      {
        statSubtitle: 'User Aspirator',
        statTitle: `${stats.countUserAspiratorRole}`,
        // statArrow: 'down',
        // statPercent: '1.10',
        statPercentColor: 'text-orange-500',
        // statDescription: 'Since yesterday',
        statIconName: 'fas fa-users',
        statIconColor: 'bg-pink-500',
      },
      {
        statSubtitle: 'User Banned',
        statTitle: `${stats.countUserBanned}`,
        // statArrow: 'up',
        // statPercent: '12',
        statPercentColor: 'text-emerald-500',
        // statDescription: 'Since last month',
        statIconName: 'fas fa-users',
        statIconColor: 'bg-red-500',
      },
    ],
  };
  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white text-blueGray-700">
        <div className="px-6 py-4 border-0">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h3 className="font-bold text-lg white">User List</h3>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <DataTableExtensions
            columns={columns}
            data={users || []}
            filterPlaceholder="Cari User"
            exportHeaders={true}
            print={false}
            fileName="User"
          >
            <DataTable
              columns={columns}
              data={users || []}
              progressPending={isLoading}
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </div>
    </Admin>
  );
}
