import { apiSlice } from '../../app/api/apiSlice';

export const proposalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProposals: builder.query({
      query: () => ({
        url: '/proposals',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({
          type: 'Proposal',
          id,
        })),
        { type: 'Proposal', id: 'LIST' },
      ],
    }),
    getProposal: builder.query({
      query: (id) => ({
        url: `/proposal/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Proposal',
          id: arg,
        },
      ],
    }),
    createProposal: builder.mutation({
      query: (body) => ({
        url: '/proposal',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Proposal', id: 'LIST' },
      ],
    }),
    updateProposal: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/proposal/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Proposal', id: 'LIST' },
      ],
    }),
    deleteProposal: builder.mutation({
      query: (id) => ({
        url: `/proposal/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Proposal', id: arg.id },
      ],
    }),
    getMyProposals: builder.query({
      query: () => ({
        url: '/my-proposals',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Proposal',
                id,
              })),
              { type: 'Proposal', id: 'LIST' },
            ]
          : [{ type: 'Proposal', id: 'LIST' }],
    }),
    getMyProposal: builder.query({
      query: (id) => ({
        url: `/my-proposal/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Proposal',
          id: arg,
        },
      ],
    }),
    getProposalByCouncilMemberDetail: builder.query({
      query: (id) => ({
        url: `/proposal/council-member/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getProposalsByCouncilMember: builder.query({
      query: () => ({
        url: '/proposals/council-member',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Proposal',
                id,
              })),
              { type: 'Proposal', id: 'LIST' },
            ]
          : [{ type: 'Proposal', id: 'LIST' }],
    }),
    //   Upload pdf file for proposal add header Content-Type: multipart/form-data
    uploadProposalFile: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/proposal/${id}/document`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Proposal', id: arg.id },
      ],
    }),
    // delete pdf file for proposal
    deleteProposalFile: builder.mutation({
      query: (id) => ({
        url: `/proposal/${id}/document`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Proposal', id: arg.id },
      ],
    }),
    //   Reject proposal by council member id and proposal id
    rejectProposal: builder.mutation({
      query: (id) => ({
        url: `/proposal/${id}/reject`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Proposal', id: arg.id },
      ],
    }),
    // statistics for proposal
    statisticByCouncilMember: builder.query({
      query: () => ({
        url: '/proposal/council-member/statistic',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetProposalsQuery,
  useGetProposalQuery,
  useCreateProposalMutation,
  useUpdateProposalMutation,
  useDeleteProposalMutation,
  useGetMyProposalsQuery,
  useGetMyProposalQuery,
  useGetProposalsByCouncilMemberQuery,
  useGetProposalByCouncilMemberDetailQuery,
  useUploadProposalFileMutation,
  useDeleteProposalFileMutation,
  useRejectProposalMutation,
  useStatisticByCouncilMemberQuery,
} = proposalApiSlice;
