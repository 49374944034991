import PropTypes from 'prop-types';

export default function LoadingData({ title, message }) {
  return (
    <div className="relative w-full h-300-px bg-white text-center flex flex-col items-center justify-center content-center border border-solid border-blueGray-200 rounded p-40">
      <h2 className="text-xl font-normal leading-normal mt-0 mb-2">{title}</h2>
      <p>{message}</p>
    </div>
  );
}

LoadingData.defaultProps = {
  title: 'Loading Data',
  message: 'Please wait while we load the data for you.',
};

LoadingData.propTypes = {
  // title of the loading data
  title: PropTypes.string,
  // message of the loading data
  message: PropTypes.string,
};
