import { apiSlice } from '../../app/api/apiSlice';

export const unitApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: () => ({
        url: '/units',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Unit',
                id,
              })),
              { type: 'Unit', id: 'LIST' },
            ]
          : [],
    }),
    getUnit: builder.query({
      query: (id) => `/unit/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Unit',
          id: arg,
        },
      ],
    }),
    createUnit: builder.mutation({
      query: (body) => ({
        url: '/unit',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Unit', id: 'LIST' }],
    }),
    updateUnit: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/unit/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Unit', id: 'LIST' }],
    }),
    deleteUnit: builder.mutation({
      query: (id) => ({
        url: `/unit/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Unit', id: arg.id }],
    }),
  }),
});

export const {
  useGetUnitsQuery,
  useGetUnitQuery,
  useCreateUnitMutation,
  useUpdateUnitMutation,
  useDeleteUnitMutation,
} = unitApiSlice;
