import React from 'react';
import { useLocation } from 'react-router-dom';

// sections
import Error1 from 'components/Sections/Error/Error1.js';
// components
import NavbarLinks from 'components/Navbars/NavbarLinks.js';
// texts for sections as props
// texts for components as props
import navbarlinkslanding2 from '_texts/presentation/navbars/navbarlinkslanding2.js';

export default function Unauthorized() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <NavbarLinks {...navbarlinkslanding2} />
      <Error1
        image={require('assets/img/illustrations/404.svg').default}
        color="dark"
        error=":("
        title="Anda tidak diizinkan untuk melihat halaman ini"
        description="Ooooups! Sepertinya kamu tersesat."
      />
    </>
  );
}
