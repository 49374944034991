import { useHistory } from 'react-router-dom';
import {
  useDeleteProposalMutation,
  useGetMyProposalsQuery,
} from './proposalApiSlice';
import Admin from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Button from '../../components/Elements/Button';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import * as Sentry from '@sentry/react';

export default function MyProposalList() {
  const history = useHistory();
  const { data: myProposals, isLoading } = useGetMyProposalsQuery();

  function onClickEdit(id) {
    history.push(`/proposal/${id}/edit`);
  }

  const [
    deleteProposal,
    { isSuccess: isSuccessDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteProposalMutation();

  const onClickDelete = async (id) => {
    try {
      await deleteProposal(id);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (isSuccessDelete) {
      toast.success('Successfully deleted proposal', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (isErrorDelete) {
      toast.error(`Error deleting proposal ${errorDelete?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccessDelete, isErrorDelete, errorDelete]);

  function onClickUpload(id) {
    history.push(`/proposal/${id}/upload`);
  }

  const columns = [
    {
      name: 'Subjek',
      selector: (row) => row.subject,
      sortable: true,
      cellExport: (row) => row.subject,
    },
    {
      name: 'Deskripsi',
      selector: (row) => row.description,
      sortable: true,
      cellExport: (row) => row.description,
    },
    {
      name: 'Anggota Dewan',
      selector: (row) => row.council_member?.full_name,
      sortable: true,
      cellExport: (row) => row.council_member?.full_name,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cellExport: (row) => row.status,
    },
    {
      name: 'Dokumen',
      selector: (row) =>
        row.proposal_document && (
          <a href={row.proposal_document}>
            <i className="fas fa-file-pdf text-4xl" />
          </a>
        ),
      sortable: true,
      cellExport: (row) => row.document,
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <div className="flex flex-wrap">
          <Button
            size="sm"
            type="button"
            color="lightBlue"
            children="Unggah Proposal"
            onClick={() => onClickUpload(row.id)}
          />
          <Button
            size="sm"
            type="button"
            color="orange"
            children="Edit"
            onClick={() => onClickEdit(row.id)}
          />
          <Button
            size="sm"
            type="button"
            color="red"
            children="Hapus"
            onClick={() => onClickDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <Admin
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg bg-white text-blueGray-700">
        <div className="px-6 py-4 border-0">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h3 className="font-bold text-lg white">Usulan</h3>
            <Button
              type="button"
              color="lightBlue"
              children="Tambah Usulan"
              size="sm"
              onClick={() => history.push('/proposal/create')}
            />
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <DataTableExtensions
            columns={columns}
            data={myProposals || []}
            filterPlaceholder="Cari Proposal"
            exportHeaders={true}
            print={false}
            fileName="Proposal"
          >
            <DataTable
              columns={columns}
              data={myProposals || []}
              progressPending={isLoading}
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
        </div>
      </div>
    </Admin>
  );
}
