import { apiSlice } from '../../app/api/apiSlice';

export const positionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPositions: builder.query({
      query: () => ({
        url: '/positions',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        ...result.map(({ id }) => ({
          type: 'Position',
          id,
        })),
        { type: 'Position', id: 'LIST' },
      ],
    }),
    getPosition: builder.query({
      query: (id) => `/position/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'Position',
          id: arg,
        },
      ],
    }),
    createPosition: builder.mutation({
      query: (body) => ({
        url: '/position',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Position', id: 'LIST' },
      ],
    }),
    updatePosition: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/position/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Position', id: 'LIST' },
      ],
    }),
    deletePosition: builder.mutation({
      query: (id) => ({
        url: `/position/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Position', id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetPositionsQuery,
  useGetPositionQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useDeletePositionMutation,
} = positionApiSlice;
