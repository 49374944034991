import Auth1 from '../../components/Layouts/Auth/Auth1';
import navbarlinks from '../../_texts/auth/navbarlinks';
import footerauth from '../../_texts/auth/footers/footerauth';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useResetPasswordMutation } from './authApiSlice';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import linth3 from '../../assets/img/illustrations/linth3.svg';
import resetBg from '../../assets/img/illustrations/reset.svg';

export default function ResetPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const [
    resetPassword,
    { isLoading, isSuccess, isError, error },
  ] = useResetPasswordMutation();

  const onClickReset = async () => {
    try {
      await resetPassword({
        password,
        password_confirmation: passwordConfirm,
        token,
      }).unwrap();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Password reset successful', {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (isError) {
      toast.error(error?.data?.data, {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [isSuccess, isError]);

  return (
    <Auth1 image={resetBg} navbar={navbarlinks} footer={footerauth}>
      <div className="container mx-auto px-4">
        <div className="justify-center flex flex-wrap -mx-4">
          <div className="px-4 relative w-full lg:w-5/12 w-full md:w-8/12">
            <div className="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
              <div className="pb-6 rounded-t-lg overflow-hidden">
                <img
                  src={linth3}
                  alt="..."
                  className="w-full rounded-t-lg"
                  style={{ marginTop: '-46%' }}
                />
              </div>

              <div className="lg:p-10 px-4 py-4 flex-auto rounded-b-lg">
                <h4 className="text-2xl font-bold text-center">
                  Reset Password
                </h4>
                <div className="text-center text-blueGray-500 mb-5 mt-1">
                  <p>Masukkan Password Baru</p>
                </div>
                <Input
                  ref={passwordRef}
                  leftIcon="fas fa-envelope"
                  type="password"
                  placeholder="Password Baru"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                  ref={passwordConfirmRef}
                  leftIcon="fas fa-envelope"
                  type="password"
                  placeholder="Konfirmasi Password Baru"
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />

                {password === passwordConfirm ? (
                  <div className="text-center mt-6">
                    <Button
                      color="dark"
                      children="Reset"
                      onClick={onClickReset}
                    />
                  </div>
                ) : (
                  <div className="text-center mt-6">
                    <Button
                      color="lightBlue"
                      children="Reset"
                      // onClick={onClickReset}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auth1>
  );
}
