import brandImg from 'assets/img/brand/tim_60x61.png';

const textProps = {
  brand: {
    text: process.env.REACT_APP_NAME,
    image: brandImg,
    link: {
      href: '#/',
    },
  },
  activeColor: 'lightBlue',
  items: [
    {
      divider: true,
    },
    {
      title: 'Section 1',
    },
    {
      icon: 'fas fa-tv',
      text: 'Dashboard',
      active: true,
      link: {
        href: '/dashboard',
      },
    },
    {
      icon: 'fas fa-tools',
      text: 'Aspirations',
      link: {
        href: '/aspiration',
      },
    },
    {
      icon: 'fas fa-table',
      text: 'Pokir',
      link: {
        href: '#pablo',
      },
    },
    {
      divider: true,
    },
    {
      title: 'Admin',
    },
    {
      icon: 'fas fa-map-marked',
      text: 'User Management',
      link: {
        href: '/user',
      },
    },
    {
      icon: 'fas fa-map-marked',
      text: 'Council Member',
      link: {
        href: '/council-member',
      },
    },
    {
      icon: 'fas fa-map-marked',
      text: 'Unit',
      link: {
        href: '/unit',
      },
    },
    {
      icon: 'fas fa-map-marked',
      text: 'Planning Type',
      link: {
        href: '/planning-type',
      },
    },
    {
      icon: 'fas fa-map-marked',
      text: 'Position',
      link: {
        href: '/position',
      },
    },

    {
      divider: true,
    },
  ],
};
export default textProps;
