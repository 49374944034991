import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useUploadProposalFileMutation } from './proposalApiSlice';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

export default function ProposalUpload() {
  const { proposalId } = useParams();
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [
    uploadProposal,
    { isLoading, isSuccess, isError, error },
  ] = useUploadProposalFileMutation();

  const onSubmitClicked = async () => {
    try {
      const payload = new FormData();
      payload.append('file', file);
      await uploadProposal({ id: proposalId, payload });
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success('Proposal uploaded successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/proposal/me');
    } else if (isError) {
      toast.error(`Error uploading proposal ${error?.data?.data}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccess, isError, error]);
  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Unggah Proposal
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    File Dokumen
                  </label>
                  <Input
                    type="file"
                    placeholder="File Dokumen"
                    onChange={(e) => setFile(e.target.files[0])}
                    accept=".pdf"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Unggah"
                  onClick={onSubmitClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
