import { apiSlice } from '../../app/api/apiSlice';

export const regionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDistricts: builder.query({
      query: () => ({
        url: '/districts',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'District',
                id,
              })),
              { type: 'District', id: 'LIST' },
            ]
          : [],
    }),
    //   Get All Region by districtId
    getVillages: builder.query({
      query: (districtId) => ({
        url: `/districts/${districtId}/villages`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Village',
                id,
              })),
              { type: 'Village', id: 'LIST' },
            ]
          : [],
    }),
    // Get All Region by Council Member Id
    getRegionsByCouncilMemberId: builder.query({
      query: () => ({
        url: '/council-member/districts',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Region',
                id,
              })),
              { type: 'Region', id: 'LIST' },
            ]
          : [],
    }),
    // Get All District by Constituency Id
    getDistrictsByConstituencyId: builder.query({
      query: (constituencyId) => ({
        url: `/districts/constituency/${constituencyId}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'District',
                id,
              })),
              { type: 'District', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const {
  useGetDistrictsQuery,
  useGetVillagesQuery,
  useGetRegionsByCouncilMemberIdQuery,
  useGetDistrictsByConstituencyIdQuery,
} = regionApiSlice;
