import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import { useEffect, useState } from 'react';
import { useGetAuthoritiesQuery } from '../authority/authorityApiSlice';
import Select from 'react-select';
import { useGetLocalGovernmentsQuery } from '../localGovernment/localGovernmentApiSlice';
import { useGetPlanningTypesQuery } from '../planingType/planningTypeApiSlice';
import { useGetUnitsQuery } from '../unit/unitApiSlice';
import { useGetProgramsQuery } from '../program/programApiSlice';
import { useGetMissionsLocalGovernmentQuery } from '../mission/missionApiSlice';
import {
  useGetRegionsByCouncilMemberIdQuery,
  useGetVillagesQuery,
} from '../region/regionApiSlice';
import { toast } from 'react-toastify';
import {
  useGetMyAspirationQuery,
  useUpdateMyAspirationMutation,
} from './aspirationApiSlice';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';

export default function MyAspirationEdit() {
  const { aspirationId } = useParams();
  const history = useHistory();
  const [aspirationProposal, setAspirationProposal] = useState('');
  const [authorityId, setAuthorityId] = useState('');
  const [budget, setBudget] = useState(0);
  const [
    localGovernmentOrganizationId,
    setLocalGovernmentOrganizationId,
  ] = useState('');
  const [locationDetail, setLocationDetail] = useState('');
  const [missionId, setMissionId] = useState('');
  const [planningTypeId, setPlanningTypeId] = useState('');
  const [programId, setProgramId] = useState('');
  const [unitId, setUnitId] = useState('');
  const [villageId, setVillageId] = useState(0);
  const [volume, setVolume] = useState(0);
  const [yearOfPlanning, setYearOfPlanning] = useState(new Date());
  const [missionOptions, setMissionOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [districtId, setDistrictId] = useState(0);

  const {
    data: myAspiration,
    isSuccess: isSuccessGet,
  } = useGetMyAspirationQuery(aspirationId);
  useEffect(() => {
    if (isSuccessGet) {
      setAspirationProposal(myAspiration.aspiration_proposal);
      setAuthorityId(myAspiration.authority.id);
      setBudget(myAspiration.budget);
      setLocalGovernmentOrganizationId(
        myAspiration.local_government_organization.id
      );
      setLocationDetail(myAspiration.location_detail);
      setMissionId(myAspiration.mission.id);
      setPlanningTypeId(myAspiration.planning_type.id);
      setProgramId(myAspiration.program.id);
      setUnitId(myAspiration.unit.id);
      setVillageId(myAspiration.village.id);
      const villageCode = myAspiration.village.id;
      const districtCode = parseInt(villageCode.toString().substring(0, 6));
      setDistrictId(districtCode);
      setVolume(myAspiration.volume);
      setYearOfPlanning(new Date(myAspiration.year_of_planning, 0, 1));
    }
  }, [isSuccessGet, myAspiration]);

  const authorityOptions = useGetAuthoritiesQuery().data?.map((authority) => ({
    value: authority.id,
    label: authority.name,
  }));

  const localGovernmentOrganizationOptions = useGetLocalGovernmentsQuery().data?.map(
    (localGovernmentOrganization) => ({
      value: localGovernmentOrganization.id,
      label: localGovernmentOrganization.name,
    })
  );

  const {
    data: missions,
    isSuccess: isSuccessMission,
  } = useGetMissionsLocalGovernmentQuery(localGovernmentOrganizationId);
  useEffect(() => {
    if (isSuccessMission) {
      setMissionOptions(
        missions.map((mission) => ({
          value: mission.id,
          label: mission.name,
        }))
      );
    }
  }, [isSuccessMission, missions]);

  function onChangeLocalGovernment(selectedOption) {
    setLocalGovernmentOrganizationId(selectedOption.value);
  }

  const { data: programs, isSuccess: isSuccessProgram } = useGetProgramsQuery({
    localGovernmentId: localGovernmentOrganizationId,
    missionId,
  });
  useEffect(() => {
    if (isSuccessProgram) {
      setProgramOptions(
        programs.map((program) => ({
          value: program.id,
          label: program.name,
        }))
      );
    }
  }, [isSuccessProgram, programs]);

  const planningTypeOptions = useGetPlanningTypesQuery().data?.map(
    (planningType) => ({
      value: planningType.id,
      label: planningType.name,
    })
  );

  const unitOptions = useGetUnitsQuery().data?.map((unit) => ({
    value: unit.id,
    label: unit.name,
  }));
  const districtOptions = useGetRegionsByCouncilMemberIdQuery().data?.map(
    (district) => ({
      value: district.id,
      label: district.name,
    })
  );
  const { data: villages, isSuccess: isSuccessVillage } = useGetVillagesQuery(
    districtId
  );
  useEffect(() => {
    if (isSuccessVillage) {
      setVillageOptions(
        villages?.map((village) => ({
          value: village.id,
          label: village.name,
        }))
      );
    }
  }, [isSuccessVillage, villages]);

  const [
    updateMyAspiration,
    { isSuccess: isSuccessCreate, isError: isErrorCreate, error: errorCreate },
  ] = useUpdateMyAspirationMutation();
  const onSaveClicked = async () => {
    console.log('onSaveClicked', yearOfPlanning.getFullYear());
    try {
      await updateMyAspiration({
        aspirationId,
        aspiration_proposal: aspirationProposal,
        authority_id: authorityId,
        budget: parseInt(budget),
        local_government_organization_id: localGovernmentOrganizationId,
        location_detail: locationDetail,
        mission_id: missionId,
        planning_type_id: planningTypeId,
        program_id: programId,
        unit_id: unitId,
        village_id: villageId,
        volume: parseInt(volume),
        years_of_planning: yearOfPlanning.getFullYear(),
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    if (isSuccessCreate) {
      toast.success('Aspiration Proposal updated', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      history.push('/my-aspiration');
    } else if (isErrorCreate) {
      toast.error(errorCreate, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }, [isSuccessCreate, isErrorCreate, errorCreate]);
  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        <div className="px-4 py-5 flex-auto">
          <form>
            <div className="container mx-auto px-4">
              <h3 className="text-3xl font-semibold mt-4 mb-6">
                Ubah Aspirasi
              </h3>
              <div className="flex flex-wrap -mx-4">
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Tahun Perencanaan
                  </label>
                  <Datetime
                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring w-full"
                    onChange={(date) => setYearOfPlanning(date)}
                    initialValue={yearOfPlanning}
                    initialViewDate={yearOfPlanning}
                    dateFormat="YYYY"
                    timeFormat={false}
                    input={false}
                    value={yearOfPlanning}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Aspirasi Proposal
                  </label>
                  <Input
                    type="text"
                    placeholder="Pembangunan Jalan Pelutan"
                    value={aspirationProposal}
                    onChange={(e) => setAspirationProposal(e.target.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Kewenangan
                  </label>
                  <Select
                    placeholder="Pilih Kewenangan"
                    options={authorityOptions}
                    onChange={(e) => setAuthorityId(e.value)}
                    value={authorityOptions?.find(
                      (option) => option.value === authorityId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    OPD
                  </label>
                  <Select
                    placeholder="Pilih OPD"
                    options={localGovernmentOrganizationOptions}
                    onChange={(e) => onChangeLocalGovernment(e)}
                    value={localGovernmentOrganizationOptions?.find(
                      (option) => option.value === localGovernmentOrganizationId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Misi
                  </label>
                  <Select
                    placeholder="Pilih Misi"
                    options={missionOptions}
                    onChange={(e) => setMissionId(e.value)}
                    value={missionOptions.find(
                      (option) => option.value === missionId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Program
                  </label>
                  <Select
                    placeholder="Pilih Program"
                    options={programOptions}
                    onChange={(e) => setProgramId(e.value)}
                    value={programOptions.find(
                      (option) => option.value === programId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Jenis Perencanaan
                  </label>
                  <Select
                    placeholder="Pilih Jenis Perencanaan"
                    options={planningTypeOptions}
                    onChange={(e) => setPlanningTypeId(e.value)}
                    value={planningTypeOptions?.find(
                      (option) => option.value === planningTypeId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Volume
                  </label>
                  <Input
                    type="number"
                    placeholder="Volume"
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Satuan
                  </label>
                  <Select
                    placeholder="Pilih Satuan"
                    options={unitOptions}
                    onChange={(e) => setUnitId(e.value)}
                    value={unitOptions?.find(
                      (option) => option.value === unitId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Budget
                  </label>
                  <Input
                    type="number"
                    placeholder="Budget"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Kecamatan
                  </label>
                  <Select
                    placeholder="Pilih Kecaamatan"
                    options={districtOptions}
                    onChange={(e) => setDistrictId(e.value)}
                    value={districtOptions?.find(
                      (option) => option.value === districtId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Desa
                  </label>
                  <Select
                    placeholder="Pilih Desa"
                    options={villageOptions}
                    onChange={(e) => setVillageId(e.value)}
                    value={villageOptions?.find(
                      (option) => option.value === villageId
                    )}
                  />
                </div>
                <div className="px-4 pb-2 relative w-full lg:w-12/12">
                  <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                    Detail Lokasi
                  </label>
                  <Input
                    type="text-area"
                    rows="3"
                    placeholder="Detail Lokasi"
                    value={locationDetail}
                    onChange={(e) => setLocationDetail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-12 mb-8">
                <Button
                  type="button"
                  color="light"
                  children="Simpan"
                  onClick={onSaveClicked}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Admin1>
  );
}
