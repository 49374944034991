const textProps = {
  title: "Let's keep in touch!",
  copyright:
    `Copyright © ${
      new Date().getFullYear()
    } DPRD KABUPATEN PEMALANG.`,
  socials: [
    {
      icon: 'twitter',
      link: 'https://twitter.com/DPRD_Pemalang',
    },
    {
      icon: 'facebook',
      link: 'https://www.facebook.com/sekretariatdprdpemalang/',
    },
  ],
  links: [
    {
      name: 'Other Resources',
      items: [
        {
          href: '#',
          target: '_blank',
          children: 'License',
        },
        {
          href: '#',
          target: '_blank',
          children: 'Terms & Conditions',
        },
        {
          href: '#',
          target: '_blank',
          children: 'Privacy Policy',
        },
        {
          href: '#',
          target: '_blank',
          children: 'Contact Us',
        },
      ],
    },
  ],
};
export default textProps;
