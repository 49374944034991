import Admin1 from '../../components/Layouts/Admin/Admin1';
import sidebar from '../../_texts/admin/sidebar/sidebar';
import navbarsearchusersettings2 from '../../_texts/admin/navbars/navbarsearchusersettings2';
import { headerstatcards } from '../../global/propAdmin';
import footeradmin from '../../_texts/admin/footers/footeradmin';
import Input from '../../components/Elements/Input';
import Select from 'react-select';
import Button from '../../components/Elements/Button';
import { useHistory } from 'react-router-dom';
import { useGetElementsQuery } from '../element/elementApiSlice';
import { useGetCouncilMembersQuery } from '../councilMember/councilMemberApiSlice';
import { useEffect, useState } from 'react';
import {
  useGetMyProposalQuery,
  useUpdateProposalMutation,
} from './proposalApiSlice';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';
import {
  useGetDistrictsByConstituencyIdQuery,
  useGetVillagesQuery,
} from '../region/regionApiSlice';
import { useGetFractionsQuery } from '../fraction/fractionApiSlice';
import { useGetConstituencyByFactionIdQuery } from '../constituency/constituencyApiSlice';
import LoadingData from '../../components/Layouts/Admin/LoadingData';

export default function ProposalEdit() {
  const { proposalId } = useParams();
  const history = useHistory();
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [elementId, setElementId] = useState('');
  const [factionId, setFactionId] = useState(null);
  const [constituencyId, setConstituencyId] = useState(null);
  const [councilMemberId, setCouncilMemberId] = useState('');
  const [villageId, setVillageId] = useState('');
  const [districtId, setDistrictId] = useState('');
  const [locationDetail, setLocationDetail] = useState('');
  const elementOptions = useGetElementsQuery().data?.map((element) => ({
    value: element.id,
    label: element.name,
  }));
  const factionOptions = useGetFractionsQuery().data?.map((fraction) => ({
    value: fraction.id,
    label: fraction.name,
  }));
  const councilMemberOptions = useGetCouncilMembersQuery().data?.map(
    (councilMember) => ({
      value: councilMember.id,
      label: councilMember?.user?.name,
    })
  );
  const [districtOptions, setDistrictOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [constituencyOptions, setConstituencyOptions] = useState([]);

  const {
    data: proposal,
    isSuccess: isSuccessProposal,
  } = useGetMyProposalQuery(proposalId);
  useEffect(() => {
    if (isSuccessProposal) {
      setSubject(proposal.subject);
      setDescription(proposal.description);
      setElementId(proposal.element.id);
      setCouncilMemberId(proposal.council_member.id);
      setConstituencyId(proposal.constituency.id);
      setFactionId(proposal.faction.id);
      const vilcode = proposal.village.id;
      const distcode = parseInt(vilcode.toString().substring(0, 6));
      setVillageId(proposal.village.id);
      setDistrictId(distcode);
      setLocationDetail(proposal.location_detail);
    }
  }, [proposal, isSuccessProposal]);

  const {
    data: constituencyData,
    isSuccess: isConstituencySuccess,
  } = useGetConstituencyByFactionIdQuery(factionId);
  useEffect(() => {
    if (isConstituencySuccess) {
      setConstituencyOptions(
        constituencyData?.map((constituency) => ({
          value: constituency.id,
          label: constituency.name,
        }))
      );
    }
  }, [isConstituencySuccess, constituencyData]);

  const {
    data: districtData,
    isSuccess: isDistrictSuccess,
  } = useGetDistrictsByConstituencyIdQuery(constituencyId);
  useEffect(() => {
    if (isDistrictSuccess) {
      setDistrictOptions(
        districtData?.map((district) => ({
          value: district.id,
          label: district.name,
        }))
      );
    }
  }, [isDistrictSuccess, districtData]);

  const {
    data: villageData,
    isSuccess: isVillageSuccess,
  } = useGetVillagesQuery(districtId);
  useEffect(() => {
    if (isVillageSuccess) {
      setVillageOptions(
        villageData?.map((village) => ({
          value: village.id,
          label: village.name,
        }))
      );
    }
  }, [isVillageSuccess, villageData]);

  const [
    updateProposal,
    { isLoading, isSuccess, isError, error },
  ] = useUpdateProposalMutation();

  const onSaveClicked = async () => {
    try {
      console.log('save', elementId);
      await updateProposal({
        id: proposalId,
        subject,
        description,
        element_id: elementId,
        council_member_id: councilMemberId,
        village_id: villageId,
        location_detail: locationDetail,
      }).unwrap();
    } catch (err) {
      Sentry.captureException('Failed to save proposal: ', err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Proposal saved successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/proposal/me');
    } else if (isError) {
      toast.error('Failed to save proposal', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [isSuccess, isError]);

  // useEffect(() => {
  //   setCouncilMemberId('');
  // }, [factionId, constituencyId]);
  // useEffect(() => {
  //   setConstituencyId('');
  // }, [factionId]);
  return (
    <Admin1
      sidebar={sidebar}
      navbar={navbarsearchusersettings2}
      header={headerstatcards}
      footer={footeradmin}
    >
      {isSuccessProposal ? (
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
          <div className="px-4 py-5 flex-auto">
            <form>
              <div className="container mx-auto px-4">
                <h3 className="text-3xl font-semibold mt-4 mb-6">
                  Ubah Usulan
                </h3>
                <div className="flex flex-wrap -mx-4">
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Usulan
                    </label>
                    <Input
                      type="text"
                      placeholder="Usulan"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Deskripsi
                    </label>
                    <Input
                      type="text"
                      placeholder="Deskripsi"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Fraksi
                    </label>
                    <Select
                      placeholder="Pilih Fraksi"
                      options={factionOptions}
                      onChange={(e) => setFactionId(e.value)}
                      value={
                        factionId
                          ? factionOptions?.find(
                              (option) => option.value === factionId
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Dapil
                    </label>
                    <Select
                      placeholder="Pilih Dapil"
                      options={constituencyOptions}
                      onChange={(e) => setConstituencyId(e.value)}
                      value={
                        constituencyId
                          ? constituencyOptions?.find(
                              (option) => option.value === constituencyId
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Anggota Dewan
                    </label>
                    <Select
                      placeholder="Pilih Anggota Dewan"
                      options={councilMemberOptions}
                      onChange={(e) => setCouncilMemberId(e.value)}
                      value={
                        councilMemberId
                          ? councilMemberOptions?.find(
                              (option) => option.value === councilMemberId
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Element
                    </label>
                    <Select
                      placeholder="Pilih Element"
                      options={elementOptions}
                      onChange={(e) => setElementId(e.value)}
                      value={
                        elementId
                          ? elementOptions?.find(
                              (option) => option.value === elementId
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Kecamatan
                    </label>
                    <Select
                      placeholder="Pilih Kecamatan"
                      options={districtOptions}
                      onChange={(e) => setDistrictId(e.value)}
                      value={
                        districtId
                          ? districtOptions?.find(
                              (option) => option.value === districtId
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Desa
                    </label>
                    <Select
                      placeholder="Pilih Desa"
                      options={villageOptions}
                      onChange={(e) => setVillageId(e.value)}
                      value={
                        villageId
                          ? villageOptions?.find(
                              (option) => option.value === villageId
                            )
                          : null
                      }
                    />
                  </div>
                  <div className="px-4 pb-2 relative w-full lg:w-12/12">
                    <label className="block uppercase text-blueGray-700 text-xs font-bold mb-2 ml-1">
                      Detail Lokasi
                    </label>
                    <Input
                      type="textarea"
                      placeholder="Detail Lokasi"
                      value={locationDetail}
                      onChange={(e) => setLocationDetail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-between mt-12 mb-8">
                  <Button
                    type="button"
                    color="light"
                    children="Simpan"
                    onClick={onSaveClicked}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingData />
      )}
    </Admin1>
  );
}
