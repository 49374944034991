import { apiSlice } from '../../app/api/apiSlice';

export const planningTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlanningTypes: builder.query({
      query: () => ({
        url: '/planning-types',
        method: 'GET',
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'PlanningType',
                id,
              })),
              { type: 'PlanningType', id: 'LIST' },
            ]
          : [],
    }),
    getPlanningType: builder.query({
      query: (id) => `/planning-type/${id}`,
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        {
          type: 'PlanningType',
          id: arg,
        },
      ],
    }),
    createPlanningType: builder.mutation({
      query: (body) => ({
        url: '/planning-type',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PlanningType', id: 'LIST' },
      ],
    }),
    updatePlanningType: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/planning-type/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PlanningType', id: 'LIST' },
      ],
    }),
    deletePlanningType: builder.mutation({
      query: (id) => ({
        url: `/planning-type/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PlanningType', id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetPlanningTypesQuery,
  useGetPlanningTypeQuery,
  useCreatePlanningTypeMutation,
  useUpdatePlanningTypeMutation,
  useDeletePlanningTypeMutation,
} = planningTypeApiSlice;
